import React from "react";

const MyAvatar = ({number, name, isBig = false}) => {

  const getLastDigitsOfNumber = (num) => !num ? '' : num.substr(num.length - 3);
  const getTextFirstLetters = text => text.split(" ").map(([v])=> v);

  const pickColor = (number) => {
    const colors = [
      'rgb(120,181,72)',
      'rgb(93,154,242)',
      'rgb(200,11,200)',
      'rgb(10,100,202)',
      'rgb(105,29,32)',
      'rgb(120,100,212)',
      'rgb(200,181,72)',
      'rgb(100,101,100)',
      'rgb(0,0,0)',
      'rgb(0,11,172)',
    ];

    return colors[number ? number.substr(number.length - 1) : 0];
  };

  return (
    <div
      className={"avatar-circle circle-flex-container " + (isBig && 'avatar-circle-big')}
      style={{background: pickColor(number)}}
    >
      <span className="white">{name ? getTextFirstLetters(name) : getLastDigitsOfNumber(number)}</span>
    </div>
  );
};

export default MyAvatar;
