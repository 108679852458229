import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import axios from 'axios';

import NextApp from './NextApp';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
// Add this import:
import {AppContainer} from 'react-hot-loader';

import { Auth0Provider } from "./react-auth0-spa";
import config from "./auth_config.json";
import history from "./util/history";

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  });
}

// Wrap the rendering in a function:
const render = () => {
  ReactDOM.render(
    // Wrap App inside AppContainer
      <>
        <Auth0Provider
          domain={config.domain}
          client_id={config.clientId}
          audience={config.audience}
          redirect_uri={window.location.origin}
          onRedirectCallback={onRedirectCallback}
        >
          <AppContainer>
            <NextApp/>
          </AppContainer>
        </Auth0Provider>
      </>,
    document.getElementById('root')
  );
};

// Do this once
// TODO: service worker cached index.html file so we need to suppress it for now
unregisterServiceWorker();

// Render once
render();

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}

axios.interceptors.request.use(config => {
    if(config.url.startsWith(process.env.REACT_APP_API_URL) && window.sessionStorage.getItem('jwt')?.length) {
        const headers = {
            'Authorization': 'Bearer ' + window.sessionStorage.getItem('jwt'),
            'x-auth-provider': window.sessionStorage.getItem('x-auth-provider'),
            'x-refresh-token': window.sessionStorage.getItem('x-refresh-token'),
            'account': window.sessionStorage.getItem('account')
        };

        Object.assign(config.headers, headers);
    }
    return config;
});
