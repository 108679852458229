import Moment from "moment";
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import axios from "axios";
import { isShoperApp } from "./appType";

export const formatTime = (time) => {
  return isShoperApp() ? Moment(time).format('YYYY-MM-DD HH:mm:ss') : new Date(time).toLocaleString();
};

export const formatDate = (time) => {
    return isShoperApp() ? Moment(time).format('YYYY-MM-DD') : new Date(time).toLocaleDateString();
};

export const hourMinuteOnly = (time) => {
  return Moment(time).format('HH:mm');
};

export const clone = (elements) => {
  return JSON.parse(JSON.stringify(elements));
};

export const matchUser = (userId, users) => {
  return users.find(oneUser => {
    return userId === oneUser.user_id
  });
};

export const trimIfLongerThan = (aString, length) => {
  if (aString && aString.length > length) {
    return aString.substring(0, length) + "...";
  }
  return aString;
};

const getUrlParamValue = ( paramName, url ) => {
  // eslint-disable-next-line
  const name = paramName.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
  // eslint-disable-next-line
  const regex = new RegExp( "[\\?&]"+name+"=([^&#]*)" );
  // eslint-disable-next-line
  const results = regex.exec( url );
  return results == null ? null : results[1];
};

export const getConversationFromUrl = (urlSearch, conversations) => {
  const id = getUrlParamValue('id', urlSearch);
  return !id ? null : conversations.find(conversation => conversation._id.includes(id));
};

export const getSelectedTabFromUrl = (urlSearch) => {
  return getUrlParamValue('tab', urlSearch);
};

export const filterMessagesByType = (messages, type) => {
  return messages.filter(oneMessage => {
    return oneMessage.channel === type
  });
};

export const addFrontPlusIfMissing = (number) => {
  if (!number) {
    return ''
  }
  if (number[0] === '+') {
    return number;
  }
  return '+' + number;
};

export const formatPhoneNumberForDisplay = (number) => {
  const phoneNumber = parsePhoneNumberFromString(addFrontPlusIfMissing(number));
  return phoneNumber ? phoneNumber.formatInternational() : number;
};

export const getCountryCode = (number) => {
    const phoneNumber = parsePhoneNumberFromString(addFrontPlusIfMissing(number));
    return phoneNumber?.country;
};

export const chunkArray = (array, size) =>
    array.length > size ? [array.slice(0, size), ...chunkArray(array.slice(size), size)] : [array];


export const getUrlPatternFromSettings = async () => {
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/settings`);
  const urlPart = data.urlType === 'TWO_WAY' ? `/${data.urlPart}` : '';
  return `${data.url}${urlPart}/XXXXXX`;
}
