import React, {useState, useEffect} from 'react';
import {Input} from "antd";
import CountryFlag from "../../../CountryFlag/CountryFlag";
import './PhoneNumberInput.scss'

const PhoneNumberInput = ({
  countryCode,
  callingCode,
  onClientNumberChange,
  clearValue
}) => {

  const [numberValue, setNumberValue] = useState();

  useEffect(() => {
      if(clearValue) {
          setNumberValue('')
      }
  }, [clearValue]);

  const onInputChange = event => {
    const { value } = event.target;
    setNumberValue(value);
    onClientNumberChange(`+${callingCode}${value}`);
  };

  return (
    <div className="phone-number-input">
      <span className="flag-wrapper">
        <CountryFlag countryCode={countryCode} />
        <span className="prefix">+{callingCode}</span>
      </span>
      <Input
        id="phone-number"
        type="number"
        required
        value={numberValue}
        onChange={onInputChange}
      />
    </div>
  );
};

export default PhoneNumberInput;
