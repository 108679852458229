import React from "react";
import {formatPhoneNumberForDisplay, formatTime} from "../../../../../util/helpers";
import MyAvatar from "../../../Conversation/MyAvatar";
import IntlMessages from "../../../../../util/IntlMessages";
import PropTypes from "prop-types";


const UserCell = ({chat, selectedChatId, onSelectUser, isUnread}) => {

  const getLastMessageShort = (lastMessage) => {
    if (lastMessage) {
      if (lastMessage.channel === 'system') {
        return <IntlMessages id={lastMessage.body}/>
      }
      return lastMessage.body && lastMessage.body.substring(0, 25) + "..."
    }
    return '';
  };

  const getLastMessageTime = (lastMessage) => {
    if (lastMessage) {
      return formatTime(lastMessage.time);
    }
    return '';
  };

  const getName = () => chat.contactDetails && (chat.contactDetails.properties.name || chat.contactDetails.properties.surname)
      ? `${chat.contactDetails.properties.name || ''} ${chat.contactDetails.properties.surname || ''}`
      : formatPhoneNumberForDisplay(chat.clientNumber)

  return (
    <div
      className={`gx-chat-user-item ${selectedChatId === chat._id ? 'active' : ''} ${isUnread ? 'unread' : ''}`}
      onClick={() => {onSelectUser(chat)}}
    >
      <div className="gx-chat-user-row">
        <div className="gx-chat-avatar">
          <div className="gx-status-pos">
            <MyAvatar
                number={chat.clientNumber}
                name={chat.contactDetails && (chat.contactDetails.properties.name || chat.contactDetails.properties.surname)
                    ? `${chat.contactDetails.properties.name || ''} ${chat.contactDetails.properties.surname || ''}`
                    : null}
            />
            <span className={`gx-status gx-small gx-${chat.account}`}/>
          </div>
        </div>

        <div className="gx-chat-info">
          <span className="gx-name h4 gx-text-truncate" title={getName()}>{getName()}</span>
          <div className="gx-chat-info-des gx-text-truncate">{getLastMessageShort(chat.lastMessage)}</div>
          <div className="gx-last-message-time">{getLastMessageTime(chat.lastMessage)}</div>
        </div>

      </div>
    </div>
  )
};

export default UserCell;

UserCell.propTypes = {
  chat: PropTypes.any,
  isUnread: PropTypes.bool,
  onSelectUser: PropTypes.func,
  selectedChatId: PropTypes.string
};
