/*
 * @param conversations - straight form the global state (messages) - Redux store
 */
export const sortMessagesMyQueuedTeam = (conversations) => {
  const sortedMessages = {
    my: [],
    queued: [],
    team: [],
    spam: [],
  };

  const userSub = sessionStorage.getItem('sub');

  const isAssignedToMe = (assignedTo) => {
    return userSub === assignedTo;
  };

  const isTeam = (assignedTo) => {
    if (!!assignedTo === false) {
      return false
    }
    return userSub !== assignedTo;
  };

  conversations.forEach(oneConversation => {
    if(oneConversation.isSpam) {
      sortedMessages.spam.push(oneConversation)
    } else if (isAssignedToMe(oneConversation.assignedTo)) {
      sortedMessages.my.push(oneConversation)
    } else if (isTeam(oneConversation.assignedTo)) {
      sortedMessages.team.push(oneConversation)
    } else {
      sortedMessages.queued.push(oneConversation)
    }
  });

  return sortedMessages;
};

/*
 * @param conversations - straight form the global state (messages) - Redux store
 * @param chatId - straight form the global state & URL
 *
 * @return object|null
 */
export const getSelectedConversation = (conversations, chatId) => {
  if (!conversations || !chatId) {
    return null;
  }

  return conversations.find(oneConversation => {
    return oneConversation._id === chatId;
  });
};


/*
 * @param conversations - straight form the global state (messages) - Redux store
 * @param number - string number. No "+"! But expected country code ^48...
 *
 * @return object|null
 */
export const getConversationByNumber = (conversations, number) => {
  if (!conversations || !number) {
    return null;
  }

  return conversations.find(oneConversation => {
    return oneConversation.clientNumber === number;
  });
};

export const getConversationById = (conversations, id) => {
  if (!conversations) return null;
  return conversations.find(conversation => conversation.conversationId === id);
};

export const isNewConversation = conversationId => conversationId?.startsWith('new-');
