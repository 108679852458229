import {combineReducers} from "redux";
import MyRouting from "./MyRouting";
import Settings from "./Settings";
import Messages from "./Messages";
import Ws from "./Ws";
import Stats from "./Stats";
import Users from "./Users";
import CannedResponses from "./CannedResponses";
import Accounts from "./Accounts";
import Global from "./Global";
import Plans from "./Plans";
import Balance from "./Balance";

const reducers = combineReducers({
  routing: MyRouting,
  settings: Settings,
  messages: Messages,
  ws: Ws,
  stats: Stats,
  users: Users,
  cannedResponses: CannedResponses,
  accounts: Accounts,
  global: Global,
  plans: Plans,
  balance: Balance,
});

export default reducers;
