import React, {Component} from "react";
import { injectIntl } from 'react-intl';
import {Button, Modal} from 'antd';
import {
  archiveConversation,
  assignToMe,
  sendMessage,
  setAssigned,
  setTabId,
  unarchiveConversation,
  addToSpam,
  restoreFromSpam
} from "../../../../appRedux/actions";
import connect from "react-redux/es/connect/connect";
import PropTypes from 'prop-types';
import IntlMessages from "../../../../util/IntlMessages";
import { isShoperApp } from "../../../../util/appType";

class ActionsRectangle extends Component {

  constructor(props) {
    super(props);
    this.state = {
      addPrivateNoteModalShow: false,
      privateNote: '',
      channel: 'whisper',
    }
  }

  addPrivateNoteButtonClick = () => {
    this.setState({
      addPrivateNoteModalShow: true
    });
  };

  assignToMe = () => {
    this.props.assignToMe(this.props.selectedConversation._id);
    this.props.setAssigned();
    this.props.setTabId('1');
    this.props.createNotificationInfo(this.props.intl.formatMessage({id: 'chats.conversationAssigned'}));
    this.props.setSidebarClose();
  };

  addPrivateNoteButtonClickModal = () => (
    <Modal
      title={this.props.intl.formatMessage({id: 'chats.newWhisper'})}
      onOk={this.onModalOk}
      onCancel={this.onModalCancel}
      visible={this.state.addPrivateNoteModalShow}
      closable={false}
      className={isShoperApp() ? "shoper-modal" : ""}
    >
      <div className="gx-modal-box-row">
        <div className="gx-modal-box-form-item">
          <div className="gx-form-group">
          <textarea
            id="privateNote" className="gx-border-0 ant-input gx-chat-textarea"
            onChange={this.updatePrivateNoteValue}
            value={this.state.privateNote}
            placeholder={this.props.intl.formatMessage({id: 'chats.typeInMessage'})}
          />
          </div>
        </div>
      </div>
    </Modal>
  );

  updatePrivateNoteValue = (event) => {
    this.setState({
      privateNote: event.target.value
    });
  };

  onModalCancel = () => {
    this.setState({
      addPrivateNoteModalShow: false,
      privateNote: ''
    });
    this.props.setSidebarClose();
  };

  onModalOk = () => {
    this.submitPrivateNote();
    this.setState({
      addPrivateNoteModalShow: false,
      privateNote: ''
    });
  };

  submitPrivateNote() {
    if (this.state.privateNote === '') {
      return;
    }
    this.props.sendMessage(this.state.privateNote, this.props.selectedConversation.clientNumber, '', this.state.channel);
    this.props.setSidebarClose();
  }

  archiveUnarchive = () => {
    if (this.props.isArchive) {
      this.props.unarchiveConversation(this.props.selectedConversation.conversationId);
      this.props.unArchive();
    } else {
      this.props.archiveConversation(this.props.selectedConversation.conversationId);
      this.props.archive();
    }
      this.props.setSidebarClose();
  };

  changeSpam = () => {
      if (this.props.selectedConversation.isSpam) {
          this.props.restoreFromSpam(this.props.selectedConversation._id);
          this.props.setTabId(this.props.selectedConversation.assignedTo ? '1' : '2');
          this.props.createNotificationInfo(this.props.intl.formatMessage({id: 'chats.conversationRestoredFromSpam'}));
      } else {
          this.props.addToSpam(this.props.selectedConversation._id);
          this.props.setTabId('4');
          this.props.createNotificationInfo(this.props.intl.formatMessage({id: 'chats.conversationAddedToSpam'}));
      }
      this.props.setSidebarClose();
  };

  render() {
    return (
      <div className="user-details__main actions">
        <div className="user-details__box">
          <p className='user-details__title'><IntlMessages id="chats.actions"/></p>
          <div className="user-details__buttons">

            {!this.props.selectedConversation.assignedTo && (
                <>
                  <Button
                    className="ant-btn ant-btn-primary ant-btn-sm user-details--full-width"
                    onClick={this.assignToMe}
                  >
                    <span><IntlMessages id="chats.assignToMe"/></span>
                  </Button>
                  <br/>
                </>
            )}

            <Button
              className="ant-btn ant-btn-sm user-details--full-width user-details--empty"
              onClick={this.addPrivateNoteButtonClick}
            >
              <span><IntlMessages id="chats.newWhisper"/></span>
            </Button>
            <br/>
            <Button
                danger
                className="ant-btn ant-btn-sm ant-btn ant-btn-danger user-details--full-width user-details--danger"
                onClick={this.changeSpam}
            >
              <IntlMessages id={this.props.selectedConversation.isSpam ? 'conversations.restoreFromSpam' : 'conversations.addToSpam'}/>
            </Button>
            <br/>
            <Button
                danger
                className="ant-btn ant-btn-sm ant-btn ant-btn-danger user-details--full-width user-details--danger"
                onClick={this.archiveUnarchive}
            >
              <span><IntlMessages id={this.props.isArchive ? 'chats.unarchive' : 'chats.archive'}/> <IntlMessages id="chat"/></span>
            </Button>

          </div>
        </div>
        {this.addPrivateNoteButtonClickModal()}
      </div>
    )
  }
}

ActionsRectangle.propTypes = {
  showNotificationInfo: PropTypes.func,
  selectedConversation: PropTypes.object,
  sendMessage: PropTypes.func,
  assignToMe: PropTypes.func,
};

export default injectIntl(connect(null, {
  sendMessage, assignToMe, archiveConversation, unarchiveConversation, setAssigned, setTabId, addToSpam, restoreFromSpam
})(ActionsRectangle));
