import { getData, postData } from "./httpHelpers";
import { redirectToCheckout } from "./stripeService";

export const goToPaymentSettings = async () => window.location.href = await getData(`${process.env.REACT_APP_API_URL}/payment-settings`);

export const getPlans = async (type) => await getData(`${process.env.REACT_APP_API_URL}/plan?type=${type}`);

export const getAccountPlans = async () => await getData(`${process.env.REACT_APP_API_URL}/plan/subscription`);

export const subscribePlan = async (priceId) => {
    const sessionResponse = await postData(`${process.env.REACT_APP_API_URL}/plan`, {priceId});
    await redirectToCheckout(sessionResponse.sessionId);
};
