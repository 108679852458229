import {STATS_FETCH_SUCCEEDED} from "../../constants/ActionTypes";


const initialState = {};

const stats = (state = initialState, action) => {
  switch (action.type) {
    case STATS_FETCH_SUCCEEDED:
      return {
        ...action.stats
      };
    default:
      return state;
  }
};

export default stats;