import {
  GLOBAL_HIDE_LEFT_SIDEBAR,
  GLOBAL_SCROLL_OFF,
  GLOBAL_SCROLL_ON,
  GLOBAL_SET_CHAT_ID,
  GLOBAL_SET_PERMISSIONS,
  GLOBAL_SET_TAB_ID,
  GLOBAL_SHOW_LEFT_SIDEBAR,
  GLOBAL_TURN_LOADING_OFF,
  GLOBAL_TURN_LOADING_ON,
  REMOVE_ERROR_MESSAGE
} from "../../constants/ActionTypes";

export const setTabId = (id) => ({type: GLOBAL_SET_TAB_ID, id});
export const setChatId = (id) => ({type: GLOBAL_SET_CHAT_ID, id});
export const showLeftSidebar = () => ({type: GLOBAL_SHOW_LEFT_SIDEBAR});
export const hideLeftSidebar = () => ({type: GLOBAL_HIDE_LEFT_SIDEBAR});
export const turnLoadingOn = () => ({type: GLOBAL_TURN_LOADING_ON});
export const turnLoadingOff = () => ({type: GLOBAL_TURN_LOADING_OFF});
export const turnScrollOn = () => ({type: GLOBAL_SCROLL_ON});
export const turnScrollOnForClientNumber = (clientNumber) => ({type: GLOBAL_SCROLL_ON, clientNumber});
export const turnScrollOff = () => ({type: GLOBAL_SCROLL_OFF});
export const removeErrorMessage = () => ({type: REMOVE_ERROR_MESSAGE});
export const setPermissions = permissions => ({type: GLOBAL_SET_PERMISSIONS, permissions});
