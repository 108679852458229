// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Right Sidebar
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

// Messages
export const MESSAGES_FETCH_REQUESTED = 'MESSAGES_FETCH_REQUESTED';
export const MESSAGES_FETCH_SUCCEEDED = 'MESSAGES_FETCH_SUCCEEDED';
export const MESSAGES_FETCH_FAILED = 'MESSAGES_FETCH_FAILED';

export const ONLY_MESSAGES_FETCH_REQUESTED = 'ONLY_MESSAGES_FETCH_REQUESTED';
export const ONLY_MESSAGES_FETCH_SUCCEEDED = 'ONLY_MESSAGES_FETCH_SUCCEEDED';
export const ONLY_MESSAGES_FETCH_FAILED = 'ONLY_MESSAGES_FETCH_FAILED';

export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_SUCCEEDED = 'SEND_MESSAGE_SUCCEEDED';
export const ADD_MESSAGE_FOR_NUMBER = 'ADD_MESSAGE_FOR_NUMBER';
export const ADD_INCOMING_MESSAGE_FOR_NUMBER = 'ADD_INCOMING_MESSAGE_FOR_NUMBER';
export const CHANGE_MESSAGE_FOR_NUMBER = 'CHANGE_MESSAGE_FOR_NUMBER';
export const CHANGE_CONVERSATION_SENDER = 'CHANGE_CONVERSATION_SENDER';
export const MARK_CONVERSATION_READ = 'MARK_CONVERSATION_READ';
export const MARK_CONVERSATION_READ_ON_PANEL = 'MARK_CONVERSATION_READ_ON_PANEL';

export const PIN_UNPIN_NOTE = 'PIN_UNPIN_NOTE';
export const PIN_UNPIN_NOTE_SUCCEEDED = 'PIN_UNPIN_NOTE_SUCCEEDED';
export const PIN_UNPIN_NOTE_FAILED = 'PIN_UNPIN_NOTE_FAILED';

export const CREATE_CONVERSATION = 'CREATE_CONVERSATION';
export const CREATE_CONVERSATION_SUCCEEDED = 'CREATE_CONVERSATION_SUCCEEDED';
export const ASSIGN_TO_ME = 'ASSIGN_TO_ME';

export const FETCH_CONTACT_DETAILS = 'FETCH_CONTACT_DETAILS';
export const FETCH_CONTACT_DETAILS_SUCCEEDED = 'FETCH_CONTACT_DETAILS_SUCCEEDED';
export const FETCH_CONTACT_DETAILS_EMPTY = 'FETCH_CONTACT_DETAILS_EMPTY';

export const UNARCHIVE_CONVERSATION = 'UNARCHIVE_CONVERSATION';
export const ARCHIVE_CONVERSATION = 'ARCHIVE_CONVERSATION';

export const ADD_TO_SPAM = 'ADD_TO_SPAM';
export const RESTORE_FROM_SPAM = 'RESTORE_FROM_SPAM';

// WS
export const WEBSOCKET_MESSAGE_RECEIVED = 'WEBSOCKET_MESSAGE_RECEIVED';
export const INITIALIZE_WEB_SOCKETS_CHANNEL = 'INITIALIZE_WEB_SOCKETS_CHANNEL';
export const CLEAR_WS = 'CLEAR_WS';

// Stats
export const STATS_FETCH_REQUESTED = 'STATS_FETCH_REQUESTED';
export const STATS_FETCH_SUCCEEDED = 'STATS_FETCH_SUCCEEDED';
export const STATS_FETCH_FAILED = 'STATS_FETCH_FAILED';

// Users
export const USERS_FETCH_REQUESTED = 'USERS_FETCH_REQUESTED';
export const USERS_FETCH_SUCCEEDED = 'USERS_FETCH_SUCCEEDED';
export const USERS_FETCH_FAILED = 'USERS_FETCH_FAILED';

// Canned Responses
export const CANNED_FETCH_REQUESTED = 'CANNED_FETCH_REQUESTED';
export const CANNED_FETCH_SUCCEEDED = 'CANNED_FETCH_SUCCEEDED';
export const CANNED_FETCH_FAILED = 'CANNED_FETCH_FAILED';

// Account Current
export const ACCOUNT_CURRENT_REQUESTED = 'ACCOUNT_CURRENT_REQUESTED';
export const ACCOUNT_CURRENT_SUCCEEDED = 'ACCOUNT_CURRENT_SUCCEEDED';
export const ACCOUNT_CURRENT_FAILED = 'ACCOUNT_CURRENT_FAILED';

export const SET_ASSIGNED = 'SET_ASSIGNED';
export const CLEAR_ASSIGNED = 'CLEAR_ASSIGNED';

// Global
export const GLOBAL_SET_CHAT_ID = 'GLOBAL_SET_CHAT_ID';
export const GLOBAL_SET_TAB_ID = 'GLOBAL_SET_TAB_ID';
export const GLOBAL_HIDE_LEFT_SIDEBAR = 'GLOBAL_HIDE_LEFT_SIDEBAR';
export const GLOBAL_SHOW_LEFT_SIDEBAR = 'GLOBAL_SHOW_LEFT_SIDEBAR';
export const GLOBAL_TURN_LOADING_ON = 'GLOBAL_TURN_LOADING_ON';
export const GLOBAL_TURN_LOADING_OFF = 'GLOBAL_TURN_LOADING_OFF';
export const GLOBAL_SET_PERMISSIONS = 'GLOBAL_SET_PERMISSIONS';

export const GLOBAL_SCROLL_ON = 'GLOBAL_SCROLL_ON';
export const GLOBAL_SCROLL_OFF = 'GLOBAL_SCROLL_OFF';


export const SMS_PLANS_FETCH = 'SMS_PLANS_FETCH';
export const SMS_PLANS_FETCH_SUCCEEDED = 'SMS_PLANS_FETCH_SUCCEEDED';
export const SMS_PLANS_FETCH_FAILED = 'SMS_PLANS_FETCH_FAILED';

export const WHATSAPP_PLANS_FETCH = 'WHATSAPP_PLANS_FETCH';
export const WHATSAPP_PLANS_FETCH_SUCCEEDED = 'WHATSAPP_PLANS_FETCH_SUCCEEDED';
export const WHATSAPP_PLANS_FETCH_FAILED = 'WHATSAPP_PLANS_FETCH_FAILED';

export const MONETARY_PLANS_FETCH = 'MONETARY_PLANS_FETCH';
export const MONETARY_PLANS_FETCH_SUCCEEDED = 'MONETARY_PLANS_FETCH_SUCCEEDED';
export const MONETARY_PLANS_FETCH_FAILED = 'MONETARY_PLANS_FETCH_FAILED';

export const LEAD_PLANS_FETCH = 'LEAD_PLANS_FETCH';
export const LEAD_PLANS_FETCH_SUCCEEDED = 'LEAD_PLANS_FETCH_SUCCEEDED';
export const LEAD_PLANS_FETCH_FAILED = 'LEAD_PLANS_FETCH_FAILED';

export const SUBSCRIBE_PLAN = 'SUBSCRIBE_PLAN';
export const SUBSCRIBE_PLAN_SUCCEEDED = 'SUBSCRIBE_PLAN_SUCCEEDED';
export const SUBSCRIBE_PLAN_FAILED = 'SUBSCRIBE_PLAN_FAILED';

export const GO_TO_PAYMENT_SETTINGS = 'GO_TO_PAYMENT_SETTINGS';
export const GO_TO_PAYMENT_SETTINGS_SUCCEEDED = 'GO_TO_PAYMENT_SETTINGS_SUCCEEDED';
export const GO_TO_PAYMENT_SETTINGS_FAILED = 'GO_TO_PAYMENT_SETTINGS_FAILED';

export const FETCH_BALANCE = 'FETCH_BALANCE';
export const FETCH_BALANCE_SUCCEEDED = 'FETCH_BALANCE_SUCCEEDED';
export const FETCH_BALANCE_FAILED = 'FETCH_BALANCE_FAILED';

export const ADD_BALANCE = 'ADD_BALANCE';
export const ADD_BALANCE_SUCCEEDED = 'ADD_BALANCE_SUCCEEDED';
export const ADD_BALANCE_FAILED = 'ADD_BALANCE_FAILED';

export const CHANGE_NEW_CONVERSATION_ID = 'CHANGE_NEW_CONVERSATION_ID';

export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const REMOVE_ERROR_MESSAGE = 'REMOVE_ERROR_MESSAGE';
