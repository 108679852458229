import {
    LEAD_PLANS_FETCH_SUCCEEDED,
    MONETARY_PLANS_FETCH_SUCCEEDED,
    SMS_PLANS_FETCH_SUCCEEDED,
    WHATSAPP_PLANS_FETCH_SUCCEEDED
} from "../../constants/ActionTypes";

const initialState = {
    sms: [],
    monetary: [],
    lead: [],
    whatsapp: []
};

const plans = (state = initialState, action) => {
    switch (action.type) {
        case SMS_PLANS_FETCH_SUCCEEDED:
            return {
                ...state,
                sms: [...action.plans],
            };
        case WHATSAPP_PLANS_FETCH_SUCCEEDED:
            return {
                ...state,
                whatsapp: [...action.plans],
            };
        case MONETARY_PLANS_FETCH_SUCCEEDED:
            return {
                ...state,
                monetary: [...action.plans],
            };
        case LEAD_PLANS_FETCH_SUCCEEDED:
            return {
                ...state,
                lead: [...action.plans],
            };
        default:
            return state;
    }
};

export default plans;
