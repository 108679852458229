import React, {Component} from "react";
import {Modal} from "antd";
import { injectIntl } from 'react-intl';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import connect from "react-redux/es/connect/connect";
import IntlTelInput from 'react-intl-tel-input';
import SelectSender from "../../SelectSender/SelectSender";
import { changeConversationSender, createConversation, setChatId, turnScrollOn } from "../../../../appRedux/actions";
import {getConversationByNumber} from "../utils";
import {addFrontPlusIfMissing} from "../../../../util/helpers";
import PhoneNumberInput from "../PhoneNumberInput/PhoneNumberInput";
import './NewConversationModal.scss';
import { isShoperApp } from "../../../../util/appType";

class NewConversationModal extends Component {
  state = {
    wrongFormatError: '',
    newNumber: '',
    newNumberCountryCallingCode: '48',
    newNumberCountryCode: 'PL',
    senderNumber: ''
  };

  componentDidMount() {
    const defaultNumber = this.props.accounts.phoneNumbersAll.find(entry => entry.isDefault);
    this.updateSenderNumber(
        defaultNumber ? defaultNumber.number : this.props.accounts.phoneNumbersAll[0]?.number
    );
  }

  onModalOk = () => {
    const number = parsePhoneNumberFromString(addFrontPlusIfMissing(this.state.newNumber));

    if (!number?.isValid()) {
      this.wrongFormat();
      return false;
    }

    const countryCodeAndNumber = number.countryCallingCode + number.nationalNumber;
    const existingConversation = getConversationByNumber(this.props.conversations, countryCodeAndNumber);

    if (existingConversation) {
      this.setState({
        newNumber: '',
        wrongFormatError: ''
      }, () => {
        this.props.setChatId(existingConversation._id);
        this.props.changeConversationSender(this.state.senderNumber, countryCodeAndNumber)
        this.props.hideNewConversationModal();
        this.props.turnScrollOn();
      });
    } else {
      this.props.createConversation(countryCodeAndNumber, this.state.senderNumber ? this.state.senderNumber : this.props.accounts.phoneNumbersAll[0].number);
      this.setState({
        newNumber: '',
        wrongFormatError: ''
      }, () => {
        this.props.setChatId(this.props.conversations[this.props.conversations.length-1]._id);
        this.props.hideNewConversationModal();
        this.props.turnScrollOn();
      });
    }
  };

  wrongFormat = () => {
    this.setState({
      wrongFormatError: this.props.intl.formatMessage({id: 'chats.wrongPhoneNumber'})
    });
  };

  updateNewNumber = number => this.setState({newNumber: number});

  updateNewNumberFromIntlTelInput = (isValid, newNumber, selectedCountryData, fullNumber) => {
    this.setState({newNumber: fullNumber});
  };

  updateSenderNumber = newNumber => {
    this.setState({senderNumber: newNumber});
    this.setPrefixClientPhoneNumber(newNumber);
  };

  setPrefixClientPhoneNumber = senderNumber => {
      const senderNumberObject = parsePhoneNumberFromString(`+${senderNumber}`);

      if (senderNumberObject && senderNumberObject.country !== this.state.newNumberCountryCode) {
          this.setState({
              newNumberCountryCallingCode: senderNumberObject.countryCallingCode,
              newNumberCountryCode: senderNumberObject.country
          });
      }
  };

  getSenderCustomName = () =>this.props.accounts?.phoneNumbersAll?.find(item =>  item.number === this.state.senderNumber)?.customName;

  render() {
    return (
      <Modal
        title={this.props.intl.formatMessage({id: 'chats.enterPhoneNumber'})}
        toggle={this.props.hideNewConversationModal}
        visible={this.props.visible}
        closable={false}
        onOk={this.onModalOk}
        onCancel={this.props.hideNewConversationModal}
        width={'fit-content'}
        className={isShoperApp() ? "shoper-modal" : ""}
      >
        <div className="gx-modal-box-row">
          <div className="gx-modal-box-form-item">
            <div className="gx-form-group gx-text-center">
              <>
                {this.getSenderCustomName() ? (
                  <IntlTelInput
                      nationalMode={false}
                      value={this.state.newNumber || `+${this.state.newNumberCountryCallingCode}`}
                      format={true}
                      onPhoneNumberChange={this.updateNewNumberFromIntlTelInput}
                      />
                ) : (
                  <PhoneNumberInput
                      countryCode={this.state.newNumberCountryCode}
                      callingCode={this.state.newNumberCountryCallingCode}
                      onClientNumberChange={this.updateNewNumber}
                      clearValue={!this.state.newNumber}
                  />
                 )}
              </>
            </div>
          </div>
        </div>
        <div className="gx-modal-box-row gx-text-center sender-wrapper">
          <SelectSender
            selectedValue={this.state.senderNumber}
            senderList={this.props.accounts.phoneNumbersAll}
            updateSenderNumber={this.updateSenderNumber}
          />
        </div>
        <div className="gx-text-center">{this.state.wrongFormatError}</div>
      </Modal>
    );
  }
}


const mapStateToProps = (state) => ({
  accounts: state.accounts,
  conversations: state.messages,
});

export default injectIntl(connect(mapStateToProps, {
  createConversation,
  changeConversationSender,
  setChatId,
  turnScrollOn
})(NewConversationModal));
