import {USERS_FETCH_SUCCEEDED} from "../../constants/ActionTypes";


const initialState = [];

const users = (state = initialState, action) => {
  switch (action.type) {
    case USERS_FETCH_SUCCEEDED:
      return [...action.users];
    default:
      return state;
  }
};

export default users;