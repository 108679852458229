import React from "react";
import loader from "../../assets/images/logo.svg"
import shoperLoader from "../../assets/images/shoper_logo.png"
import { isShoperApp } from "../../util/appType";
import { useAuth0 } from "../../react-auth0-spa";

const CircularProgress = ({className}) => {
    const {isAuthenticated} = useAuth0();

    return <div className={`loader wm-loader ${className}`}>
        {isAuthenticated && <img src={isShoperApp() ? shoperLoader : loader} alt="loader"/>}
    </div>
};

export default CircularProgress;
