import {all, call, put, fork, takeLatest} from 'redux-saga/effects';
import {USERS_FETCH_FAILED, USERS_FETCH_REQUESTED, USERS_FETCH_SUCCEEDED} from "../../constants/ActionTypes";
import { getData } from "../../util/httpHelpers";

const fetchAllUsersFromApi = async () => await getData(`${process.env.REACT_APP_API_URL}/users`);

function* fetchAllUsers() {
  try {
    const users = yield call(fetchAllUsersFromApi);
    yield put({type: USERS_FETCH_SUCCEEDED, users});
  } catch (error) {
    console.error(error);
    yield put({type: USERS_FETCH_FAILED});
  }
}

function* fetchUsers() {
  yield takeLatest(USERS_FETCH_REQUESTED, fetchAllUsers);
}

export default function* usersSagas() {
  yield all([
    fork(fetchUsers),
  ]);
}
