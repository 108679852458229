import React, {Component} from "react";
import { injectIntl } from 'react-intl';
import { notification } from 'antd';
import Conversation from "../../../chat/Conversation";
import connect from "react-redux/es/connect/connect";
import CustomScrollbars from "util/CustomScrollbars";
import TypingArea from "./TypingArea";
import {
    fetchAccountCurrent,
    fetchCannedResponses,
    fetchOnlyMessages,
    fetchUsers,
    sendMessage,
    turnScrollOnForClientNumber,
    removeErrorMessage,
    markConversationRead
} from "../../../../appRedux/actions";
import CircularProgress from "../../../CircularProgress";

class MyCommunication extends Component {
  state = {
    typingAreaContent: '',
    senderNumber: '',
    mmsFile: null,
    error: ''
  };

  componentDidMount() {
    this.props.fetchUsers();
    this.props.fetchAccountCurrent();
    if(this.props.selectedConversation) {
        this.props.fetchOnlyMessages(this.props.selectedConversation.conversationId);
        this.props.turnScrollOnForClientNumber(this.props.selectedConversation.clientNumber);
    }
  }

  componentDidUpdate() {
      if(this.props.selectedConversation && !this.props.selectedConversation.isMessagesFetched) {
          this.props.fetchOnlyMessages(this.props.selectedConversation.conversationId);
      }

      if(this.props.selectedConversation?.accountNumber && this.props.selectedConversation.accountNumber !== this.state.senderNumber) {
          this.updateSenderNumber(this.props.selectedConversation.accountNumber);
      }

      if(this.state.error !== this.props.global.error) {
          this.setState({error: this.props.global.error});

          if(this.props.global.error) {
              notification.open({
                  message: this.props.intl.formatMessage({id: `errorMessage.${this.props.global.error}`}),
                  onClose: this.props.removeErrorMessage()
              });
          }
      }
  }

  submitComment = () => {
    if (this.state.typingAreaContent === '') {
      return;
    }

    this.markConversationRead();
    this.props.sendMessage(
        this.state.typingAreaContent,
        this.props.selectedConversation.clientNumber,
        this.state.senderNumber,
        this.state.mmsFile ? 'mms' : 'sms',
        this.state.mmsFile
    );
    this.updateTypingAreaValue('');
  };

  updateTypingAreaValue = (text) => {
    this.setState({typingAreaContent: text});
  };

  updateMmsFile = (mmsFile) => {
    this.setState({mmsFile});
  };

  updateSenderNumber = (selectedNumber) => {
    this.markConversationRead();
    this.setState({senderNumber: selectedNumber});
  };

  handleAutoComplete = (value) => {
    this.setState((prevState) => ({
      typingAreaContent: prevState.typingAreaContent + value
    }));
  };

  focusTypingArea = () => {
    this.markConversationRead();
  };

  markConversationRead = () => {
    if (this.props.selectedConversation.isRead) return;
    this.props.markConversationRead(this.props.selectedConversation.conversationId);
  };

  render() {
    if (!this.props.selectedConversation) {
      return '';
    }

    return (
      <div className={"chat-container " + this.props.classList}>
        <div className="gx-chat-main">
            {this.props.selectedConversation.messages ? (
                <>
                  <CustomScrollbars className="gx-chat-list-scroll gx-chat-list-scroll--zoom">
                    <Conversation
                      selectedConversation={this.props.selectedConversation}
                      users={this.props.users}
                    />
                  </CustomScrollbars>

                  {!this.props.isArchive && !this.props.isTrialExpired &&
                    <TypingArea
                      message={this.state.typingAreaContent}
                      updateTypingAreaValue={this.updateTypingAreaValue}
                      updateMmsFile={this.updateMmsFile}
                      focusTypingArea={this.focusTypingArea}
                      submitComment={this.submitComment}
                      isArchive={this.props.isArchive}
                      handleAutoComplete={this.handleAutoComplete}
                      cannedResponses={this.props.cannedResponses}
                      senderNumber={this.state.senderNumber}
                      phoneNumbersAll={this.props.accounts.phoneNumbersAll}
                      updateSenderNumber={this.updateSenderNumber}
                      clientNumber={this.props.selectedConversation.clientNumber}
                    />
                  }
                </>
            ) : (
                <div className="gx-loader-view">
                    <CircularProgress/>
                </div>
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  global: state.global,
  conversations: state.messages,
  users: state.users,
  cannedResponses: state.cannedResponses,
  accounts: state.accounts
});

export default injectIntl(connect(mapStateToProps, {
  fetchUsers,
  fetchCannedResponses,
  fetchAccountCurrent,
  sendMessage,
  fetchOnlyMessages,
  turnScrollOnForClientNumber,
  removeErrorMessage,
  markConversationRead
})(MyCommunication));
