import React from "react";
import {formatTime} from "../../../../util/helpers";
import IntlMessages from "../../../../util/IntlMessages";

import './SystemMessage.less';

const SystemMessageCell = ({message}) => {

  const getMessageTest = (convo) => {
    return <IntlMessages id={convo.body}/>;
    // return convo.body;
  };

  return (
    <div className="gx-chat-item gx-flex-row-round system-message">

      <div className="gx-bubble">
        <div className="gx-message">{getMessageTest(message)}</div>
        <div className="gx-time gx-text-muted gx-text-right">{formatTime(message.time)}</div>
      </div>

    </div>
  )
};

export default SystemMessageCell;