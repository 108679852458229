import React from "react";
import { CloseOutlined } from '@ant-design/icons';
import connect from "react-redux/es/connect/connect";
import GeneralInfoRectangle from "../MyCommunication/GeneralInfoRectangle";
import AssignedRectangle from "../MyCommunication/AssignedRectangle";
import NotesRectangle from "../MyCommunication/NotesRectangle";
import ActionsRectangle from "../MyCommunication/ActionsRectangle";
import { isNewConversation } from "../utils";
import './SidebarRight.less';
import IntlMessages from "../../../../util/IntlMessages";
import { setChatId } from "../../../../appRedux/actions";

const SidebarRight = ({selectedConversation, users, createNotificationInfo, isArchive, setSidebarClose, global, setChatId}) => {

  // todo: fix
  const archive = () => setChatId('');
  const unArchive = () => {};
  const showNotificationInfo = () => {};

  return (
    <div className="sidebar-right">
      <div className="user-details">
        <div className="user-details__header has-border-bottom top-nav">
          <p><IntlMessages id="chats.details"/></p>
          <CloseOutlined className="close-icon" onClick={() => setSidebarClose()}/>
        </div>

        <GeneralInfoRectangle selectedConversation={selectedConversation} />

        <AssignedRectangle selectedConversation={selectedConversation} users={users}/>

        {!isNewConversation(global.chatId) && (
            <>
              <NotesRectangle
                users={users}
                selectedConversation={selectedConversation}
              />

              <ActionsRectangle
                showNotificationInfo={showNotificationInfo}
                selectedConversation={selectedConversation}
                isArchive={isArchive}
                archive={archive}
                unArchive={unArchive}
                createNotificationInfo={createNotificationInfo}
                setSidebarClose={setSidebarClose}
              />
            </>
        )}
      </div>
    </div>
  )
};

const mapStateToProps = (state) => ({
    global: state.global,
});

export default connect(mapStateToProps, {setChatId})(SidebarRight);
