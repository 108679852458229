import { all, call, put, fork, takeLatest } from 'redux-saga/effects';
import * as plansService from '../../util/plansService';
import {
    GO_TO_PAYMENT_SETTINGS,
    GO_TO_PAYMENT_SETTINGS_SUCCEEDED,
    GO_TO_PAYMENT_SETTINGS_FAILED,
    SMS_PLANS_FETCH,
    SMS_PLANS_FETCH_FAILED,
    SMS_PLANS_FETCH_SUCCEEDED,
    WHATSAPP_PLANS_FETCH,
    WHATSAPP_PLANS_FETCH_FAILED,
    WHATSAPP_PLANS_FETCH_SUCCEEDED,
    SUBSCRIBE_PLAN,
    SUBSCRIBE_PLAN_FAILED,
    SUBSCRIBE_PLAN_SUCCEEDED,
    MONETARY_PLANS_FETCH,
    LEAD_PLANS_FETCH,
    LEAD_PLANS_FETCH_SUCCEEDED,
    LEAD_PLANS_FETCH_FAILED,
    MONETARY_PLANS_FETCH_SUCCEEDED,
    MONETARY_PLANS_FETCH_FAILED
} from "../../constants/ActionTypes";

function* fetchSmsPlans() {
    try {
        const plans = yield call(plansService.getPlans, 'sms');
        yield put({type: SMS_PLANS_FETCH_SUCCEEDED, plans});
    } catch (error) {
        console.error(error);
        yield put({type: SMS_PLANS_FETCH_FAILED});
    }
}

function* fetchWhatsappPlans() {
    try {
        const plans = yield call(plansService.getPlans, 'whatsApp');
        yield put({type: WHATSAPP_PLANS_FETCH_SUCCEEDED, plans});
    } catch (error) {
        console.error(error);
        yield put({type: WHATSAPP_PLANS_FETCH_FAILED});
    }
}

function* fetchMonetaryPlans() {
    try {
        const plans = yield call(plansService.getPlans, 'monetary');
        yield put({type: MONETARY_PLANS_FETCH_SUCCEEDED, plans});
    } catch (error) {
        console.error(error);
        yield put({type: MONETARY_PLANS_FETCH_FAILED});
    }
}

function* fetchLeadPlans() {
    try {
        const plans = yield call(plansService.getPlans, 'lead');
        yield put({type: LEAD_PLANS_FETCH_SUCCEEDED, plans});
    } catch (error) {
        console.error(error);
        yield put({type: LEAD_PLANS_FETCH_FAILED});
    }
}

function* subscribePlan({priceId}) {
    try {
        const sessionId = yield call(plansService.subscribePlan, priceId);
        yield put({type: SUBSCRIBE_PLAN_SUCCEEDED, priceId, sessionId});
    } catch (error) {
        console.error(error);
        yield put({type: SUBSCRIBE_PLAN_FAILED});
    }
}

function* goToPaymentSettings() {
    try {
        yield call(plansService.goToPaymentSettings());
        yield put({type: GO_TO_PAYMENT_SETTINGS_SUCCEEDED});
    } catch (error) {
        console.error(error);
        yield put({type: GO_TO_PAYMENT_SETTINGS_FAILED});
    }
}

function* watchFetchSmsPlans() {
    yield takeLatest(SMS_PLANS_FETCH, fetchSmsPlans);
}

function* watchFetchWhatsappPlans() {
    yield takeLatest(WHATSAPP_PLANS_FETCH, fetchWhatsappPlans);
}

function* watchFetchMonetaryPlans() {
    yield takeLatest(MONETARY_PLANS_FETCH, fetchMonetaryPlans);
}

function* watchFetchLeadPlans() {
    yield takeLatest(LEAD_PLANS_FETCH, fetchLeadPlans);
}

function* watchSubscribePlan() {
    yield takeLatest(SUBSCRIBE_PLAN, subscribePlan);
}

function* watchGoToPaymentSettings() {
    yield takeLatest(GO_TO_PAYMENT_SETTINGS, goToPaymentSettings);
}

export default function* plans() {
    yield all([
        fork(watchFetchSmsPlans),
        fork(watchFetchWhatsappPlans),
        fork(watchFetchMonetaryPlans),
        fork(watchFetchLeadPlans),
        fork(watchSubscribePlan),
        fork(watchGoToPaymentSettings)
    ]);
}
