import {LOCATION_CHANGE} from 'react-router-redux';
import {GLOBAL_SET_TAB_ID} from "../../constants/ActionTypes";

// This initial state is *copied* from react-router-redux's
// routerReducer (the property name 'locationBeforeTransitions' is
// because this is designed for use with react-router)
const initialState = { locationBeforeTransitions: null };

const myRouting = (state = initialState, action) => {
  // This LOCATION_CHANGE case is copied from react-router-redux's routerReducer
  if (action.type === LOCATION_CHANGE) {
    return { ...state, locationBeforeTransitions: action.payload }
  }

  // Here is our code to set the location state when the user chooses
  // a different option in the menu
  if (action.type === GLOBAL_SET_TAB_ID) {
    let location = state.locationBeforeTransitions;
    const pathname = `/chat/${action.id}`;
    location = { ...location, pathname, action: 'PUSH' };

    return { ...state, locationBeforeTransitions: location };
  }

  return state;
};

export default myRouting
