/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { injectIntl } from 'react-intl';
import CustomScrollbars from "util/CustomScrollbars";
import { Tooltip } from "antd";
import { EllipsisOutlined } from '@ant-design/icons';
import Tabs, { TabPane } from 'rc-tabs';
import connect from "react-redux/es/connect/connect";
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import ChatUserList from "./../ChatUserList/ChatUserList";
import IntlMessages from "../../../../util/IntlMessages";
import {
    changeConversationSender,
    createConversation,
    markConversationRead,
    setChatId,
    setTabId, turnScrollOn
} from "../../../../appRedux/actions";
import { getConversationByNumber, sortMessagesMyQueuedTeam } from "../utils";
import { addFrontPlusIfMissing } from "../../../../util/helpers";
import ConversationsLink from "../ConversationsLink/ConversationsLink";
import './ConversationsList.less';

const ConversationsList = ({
                               global,
                               accounts,
                               messages,
                               classList,
                               intl,
                               setTabId,
                               setChatId,
                               markConversationRead,
                               createConversation,
                               changeConversationSender,
                               turnScrollOn
                           }) => {

    useEffect(() => {
        if (global.phoneNumber && accounts.phoneNumbersAll) {
            const number = parsePhoneNumberFromString(addFrontPlusIfMissing(global.phoneNumber));

            if (!number?.isValid()) {
                return;
            }

            const countryCodeAndNumber = number.countryCallingCode + number.nationalNumber;
            const existingConversation = getConversationByNumber(messages, countryCodeAndNumber);
            const senderNumber = accounts.phoneNumbersAll.find(entry => entry.isDefault)?.number;

            if (existingConversation) {
                setChatId(existingConversation._id);
                changeConversationSender(senderNumber, countryCodeAndNumber)
                turnScrollOn();
            } else {
                createConversation(countryCodeAndNumber, senderNumber);
                setChatId(messages[messages.length - 1]?._id);
                turnScrollOn();
            }
        }
    }, [accounts.phoneNumbersAll]);
    
    const getChatsTabName = (id, allLength, unreadLength) => (
        <Tooltip placement="topLeft" title={`${intl.formatMessage({id: 'chats.all'})}: ${allLength}, ${intl.formatMessage({id: 'chats.unread'})}: ${unreadLength}`}>
            <div className="gx-bubble">
                <IntlMessages id={id}/> {unreadLength ? ` (${unreadLength})`: ""}
            </div>
        </Tooltip>
    );

    const getTabPane = (data, name, key) => {
        const unreadConversations = data.filter(conversation => !conversation.isRead);

        return (
            <TabPane label={<IntlMessages id={name}/>} tab={getChatsTabName(name, data.length, unreadConversations?.length)} key={key}>
                <CustomScrollbars className={`gx-chat-sidenav-scroll-tab-${key}`}>
                    {data.length === 0 ?
                        <div className="gx-p-5"><IntlMessages id="chats.noConversationsFound"/></div>
                        :
                        <ChatUserList chatUsers={data}
                                      selectedChatId={global.chatId}
                                      onSelectUser={onSelectConversation}
                        />
                    }
                </CustomScrollbars>
            </TabPane>
        );
    };

    const onSelectConversation = (conversationElement) => {
        if (!conversationElement.isRead) {
            markConversationRead(conversationElement.conversationId);
        }

        setChatId(conversationElement._id);
    };

    const tabClick = tabNumber => setTabId(tabNumber);

    const sorted = sortMessagesMyQueuedTeam(messages);

    return (
        <div className={"gx-chat-sidenav-main " + classList}>
            <div className="gx-chat-sidenav-content chat-left-part">
                <Tabs
                    className="gx-tabs-half"
                    activeKey={global.tabId}
                    onTabClick={tabClick}
                    moreIcon={<EllipsisOutlined/>}
                >
                    {getTabPane(sorted.my, "chat.myChats", 1)}
                    {getTabPane(sorted.queued, "chat.queued", 2)}
                    {getTabPane(sorted.team, "chat.teamChats", 3)}
                    {getTabPane(sorted.spam, "chat.spam", 4)}
                </Tabs>
            </div>

            <ConversationsLink link="/my-archives" labelKey="conversationsListArchive"/>
        </div>
    );
}

const mapStateToProps = ({global, messages, accounts}) => ({global, accounts, messages});

export default injectIntl(connect(mapStateToProps, {
    setTabId,
    setChatId,
    markConversationRead,
    createConversation,
    changeConversationSender,
    turnScrollOn
})(ConversationsList));
