import React, {Component} from "react";
import {connect} from "react-redux";
import {Layout} from "antd";
import SidebarContent from "./SidebarContent";
import { isShoperApp } from "../../util/appType";
import { switchLanguage } from "../../appRedux/actions";

const {Sider} = Layout;
const DESKTOP_BREAKPOINT = isShoperApp() ? 0 : 992;

export class Sidebar extends Component {
  state = {
    width: window.outerWidth
  };

  updateWindowDimensions = () => {
    this.setState({width: window.innerWidth});
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  isShoperMenuHidden =() => isShoperApp() && ['my-archives', 'chat'].includes(this.props.pathname.substr(1))

  render() {
    const {shouldShowLeftSidebarMobile} = this.props.global;
    const {width} = this.state;

    let drawerStyle = "gx-app-sidebar gx-layout-sider-dark";
    if (width < DESKTOP_BREAKPOINT) {
      drawerStyle += shouldShowLeftSidebarMobile ? " sidebar-left-overlay" : " hide";
    }

    return (
      <Sider
          id="my-sidebar"
          className={drawerStyle}
          theme="dark"
          style={{display: this.isShoperMenuHidden() ? 'none' : 'block'}}
      >
        <SidebarContent/>
      </Sider>
    )
  }
}

const mapStateToProps = (state) => ({
  global: state.global,
  messages: state.messages,
  pathname:  state.settings.pathname
});
export default connect(mapStateToProps, {switchLanguage})(Sidebar);
