import React, {Component} from "react";
import {Avatar, Modal} from "antd";
import { UserOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import {filterMessagesByType, matchUser} from "../../../../util/helpers";
import * as PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {sendMessage} from "../../../../appRedux/actions";
import AddNoteButton from "./AddButton";
import NoteMessageCell from "../../../chat/Conversation/NoteMessageCell";
import IntlMessages from "../../../../util/IntlMessages";
import { isShoperApp } from "../../../../util/appType";

const CHANNEL_NOTE = 'note';

class NotesRectangle extends Component {
  state = {
    addNoteModalShow: false,
    editMode: false,
    showAllModalShow: false,
    note: '',
  };

  showAllClicked = () => {
    this.setState({
      showAllModalShow: true
    });
  };

  submitNote = () => {
    if (this.state.note === '') {
      return;
    }

    if (this.state.editMode) {
      // todo: potentially add a message ID to the same function
      console.log('todo: send EDIT note implement here');
    } else {
      this.props.sendMessage(this.state.note, this.props.selectedConversation.clientNumber, '', CHANNEL_NOTE)
    }

    this.setState({
      addNoteModalShow: false,
      note: ''
    });
  };

  showAddNoteModal = () => {
    this.setState({
      addNoteModalShow: true,
      editMode: false,
    });
  };

  updateNoteValue = (event) => {
    this.setState({
      note: event.target.value
    });
  };

  onModalCancel = () => {
    this.setState({
      addNoteModalShow: false,
      note: ''
    });
  };

  onShowAllModalCancel = () => {
    this.setState({
      showAllModalShow: false
    });
  };

  onEdit = (noteNext) => {
    this.setState({
      addNoteModalShow: true,
      editMode: true,
      showAllModalShow: false,
      note: noteNext,
    });
  };

  onDelete = () => {
    Modal.confirm({
      title: this.props.intl.formatMessage({id: 'chats.deleteNote.title'}),
      content: this.props.intl.formatMessage({id: 'chats.deleteNote.content'}),
      okText: this.props.intl.formatMessage({id: 'button.yes'}),
      okType: 'danger',
      cancelText: this.props.intl.formatMessage({id: 'button.no'}),
      onOk() {
        // todo:
        console.log('todo: send DELETE note implement here');
      },
    });
  };

  addNoteButtonClickModal = () => (
    <Modal
      title={this.props.intl.formatMessage({id: 'chats.newNote'})}
      onOk={this.submitNote}
      onCancel={this.onModalCancel}
      visible={this.state.addNoteModalShow}
      closable={false}
      className={isShoperApp() ? "shoper-modal" : ""}
    >
      <div className="gx-modal-box-row">
        <div className="gx-modal-box-form-item">
          <div className="gx-form-group">
          <textarea
            className="gx-border-0 ant-input gx-chat-textarea"
            onChange={this.updateNoteValue}
            value={this.state.note}
            placeholder={this.props.intl.formatMessage({id: 'chats.typeInMessage'})}
          />
          </div>
        </div>
      </div>
    </Modal>
  );

  showAllModal = (notes) => (
    <Modal
      title={this.props.intl.formatMessage({id: 'chats.notes'})}
      onOk={this.onShowAllModalCancel}
      onCancel={this.onShowAllModalCancel}
      visible={this.state.showAllModalShow}
      closable={false}
      bodyStyle={{padding: 0}}
      className={isShoperApp() ? "shoper-modal" : ""}
    >
      <div className="gx-modal-box-row">
        <div className="gx-modal-box-form-item show-all-messages">
          {notes.map((note, index) =>
            <NoteMessageCell key={index} message={note} users={this.props.users} showControls={true}
                             conversationId={this.props.selectedConversation._id} onEdit={this.onEdit}
                             onDelete={this.onDelete}/>
          )}
        </div>
      </div>
    </Modal>
  );

  render() {
    const {users, selectedConversation} = this.props;

    if (!this.props.selectedConversation.messages) {
          return '';
    }

    const notes = filterMessagesByType(selectedConversation.messages, CHANNEL_NOTE);
    const usersForNotes = notes.map(note => matchUser(note.userId, users));
    const slicedUsersForNotes = usersForNotes.slice(0, 3);
    let difference = usersForNotes.length - slicedUsersForNotes.length;
    difference = difference > 0 ? difference : null;

    return (
      <div className="user-details__main">
        <div className="user-details__box user-details__separator">
          <div className="notes-content">
            <div className="user-details__title-line">
              <p className='user-details__title'><IntlMessages id="chats.notes"/></p>
              <em className='user-details__clickable' onClick={this.showAllClicked}><IntlMessages id="chats.seeAll"/></em>
            </div>
            <div className="user-details__avatar-list">
              <AddNoteButton addButtonClicked={this.showAddNoteModal} />
              {slicedUsersForNotes.map((user, index) =>
                <Avatar
                  alt={user && user._id}
                  className="gx-size-40 gx-align-self-end"
                  key={index}
                  src={user && user.picture}
                  icon={<UserOutlined />}
                />
              )}
              <em>{difference ? `+${difference}` : ''}</em>
              {this.addNoteButtonClickModal()}
              {this.showAllModal(notes)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

NotesRectangle.propTypes = {
  users: PropTypes.array,
  selectedConversation: PropTypes.object,
};

export default injectIntl(connect(null, {sendMessage})(NotesRectangle));
