import {
    GO_TO_PAYMENT_SETTINGS,
    LEAD_PLANS_FETCH,
    MONETARY_PLANS_FETCH,
    SMS_PLANS_FETCH,
    SUBSCRIBE_PLAN,
    WHATSAPP_PLANS_FETCH
} from "../../constants/ActionTypes";

export const fetchSmsPlans = () => ({type: SMS_PLANS_FETCH});
export const fetchWhatsappPlans = () => ({type: WHATSAPP_PLANS_FETCH});
export const fetchMonetaryPlans = () => ({type: MONETARY_PLANS_FETCH});
export const fetchLeadPlans = () => ({type: LEAD_PLANS_FETCH});
export const subscribePlan = (priceId) => ({type: SUBSCRIBE_PLAN, priceId});
export const goToPaymentSettings = () => ({type: GO_TO_PAYMENT_SETTINGS});
