import {all, call, put, fork, takeLatest} from 'redux-saga/effects';
import {ACCOUNT_CURRENT_FAILED, ACCOUNT_CURRENT_REQUESTED, ACCOUNT_CURRENT_SUCCEEDED} from "../../constants/ActionTypes";
import { getData } from "../../util/httpHelpers";

const fetchAllCurrentFromApi = async () => await getData(`${process.env.REACT_APP_API_URL}/accounts/current`);

function* fetchAllCurrent() {
  try {
    const accounts = yield call(fetchAllCurrentFromApi);
    yield put({type: ACCOUNT_CURRENT_SUCCEEDED, accounts});
  } catch (error) {
    console.error(error);
    yield put({type: ACCOUNT_CURRENT_FAILED});
  }
}

function* fetchCurrentAccount() {
  yield takeLatest(ACCOUNT_CURRENT_REQUESTED, fetchAllCurrent);
}

export default function* accounts() {
  yield all([
    fork(fetchCurrentAccount),
  ]);
}
