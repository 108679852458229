import React from "react";

const AddNoteButton = ({addButtonClicked}) => {

  return (
    <div
      className="ant-avatar gx-size-40 gx-align-self-end ant-avatar-circle user-details__clickable add-note-button"
      onClick={addButtonClicked}
    >
      <span className="white">+</span>
    </div>
  )
};

export default AddNoteButton;
