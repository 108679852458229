import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import ReceivedMessageCell from "./ReceivedMessageCell/index";
import SentMessageCell from "./SentMessageCell/index";
import SystemMessageCell from "./SystemMessage";
import WhisperMessageCell from "./WhisperMessageCell";
import NoteMessageCell from "./NoteMessageCell";
import Moment from "moment";
import PropTypes from "prop-types";
import './Conversation.less';
import DateCell from "./DateCell/DateCell";
import {turnScrollOff} from "../../../appRedux/actions";
import { formatDate } from "../../../util/helpers";

const CHANNEL_NOTE = 'note';
const CHANNEL_DATE = 'date';

class Conversation extends Component {

  scrollToBottom = () => {
    if (this.messagesEnd) {
      // this.messagesEnd.scrollIntoView({behavior: "smooth"});
      this.messagesEnd.scrollIntoView();
    }
  };

  componentDidMount() {
    this.scrollToBottom();
    this.props.turnScrollOff();
  }

  componentDidUpdate(prevProps) {
    if (
      (
        this.props.global.scrollToBottom &&
        this.props.selectedConversation &&
        this.props.selectedConversation.clientNumber === this.props.global.scrollToBottom
      ) || (
        prevProps.selectedConversation &&
        this.props.selectedConversation.clientNumber !== prevProps.selectedConversation.clientNumber
      )
    ) {
      this.scrollToBottom();
      this.props.turnScrollOff();
    }
  }

  newDateSeparator = (date) => {
    return {
      account: "test",
      channel: CHANNEL_DATE,
      body: formatDate(date),
    };
  };

  addDateSeparators = (messages) => {
    let formattedMessages = [];
    messages.forEach((element, index, array) => {
      if (!array[index-1]) {
        formattedMessages.push(element);
        return;
      }

      const date1 = Moment(element.time);
      const date2 = Moment(array[index-1].time);
      if (Math.abs(date1.diff(date2, 'day')) > 1) {
        formattedMessages.push(this.newDateSeparator(date1));
      }
      formattedMessages.push(element);
    });
    return formattedMessages;
  };

  markLastMessage = (messages) => {
    const outMessages = messages.filter(message => message.direction === 'out');
    const lastOutMessage = outMessages?.pop();
    return lastOutMessage
      ? messages.map(message => message.messageId === lastOutMessage.messageId ? {...message, last: true}: message)
      : messages;
  };

  getAndSortMessages = (oneConversation) => {
    const messages = this.addDateSeparators(this.markLastMessage(oneConversation.messages));
    const noNotes = messages.filter(message => message.channel !== CHANNEL_NOTE);
    const pinnedNotes = messages.filter(message => {
      if (message.channel === CHANNEL_NOTE) {
        return message.properties && message.properties.find(prop => prop.isPinnedToTop === true);
      }
      return false
    });

    return [...noNotes, ...pinnedNotes];
  };

  render() {
    const {selectedConversation, users} = this.props;
    const messages = this.getAndSortMessages(selectedConversation);
    if (!messages || messages.length === 0) {
      return (
        <div className="gx-chat-main-content"/>
      );
    }

    return (
      <div className="gx-chat-main-content">
        {messages.map((message, index) => {
            if (message.channel === 'system') {
              return <SystemMessageCell key={index} message={message}/>
            } else if (message.channel === 'note') {
              return <NoteMessageCell key={index} message={message} users={users} conversationId={selectedConversation._id}/>
            } else if (message.channel === 'whisper') {
              return <WhisperMessageCell key={index} message={message}/>
            } else if (message.channel === 'date') {
              return <DateCell key={index} dateString={message.body}/>
            }
            return selectedConversation.clientNumber !== message.from ?
              <SentMessageCell key={index} message={message} users={users}/> :
              <ReceivedMessageCell key={index} message={message} contact={selectedConversation.contactDetails}/>
          }
        )}
        <div style={{float: "left", clear: "both"}}
             ref={(el) => {
               this.messagesEnd = el;
             }}>
        </div>
      </div>
    )
  }
}

Conversation.propTypes = {
  selectedConversation: PropTypes.any,
};

const mapStateToProps = (state) => ({
  global: state.global,
});

export default connect(mapStateToProps, { turnScrollOff })(Conversation);
