import { all, call, put, fork, takeLatest } from 'redux-saga/effects';
import * as balanceService from '../../util/balanceService';
import {
    FETCH_BALANCE_SUCCEEDED,
    FETCH_BALANCE_FAILED,
    ADD_BALANCE_SUCCEEDED,
    ADD_BALANCE_FAILED,
    FETCH_BALANCE, ADD_BALANCE
} from "../../constants/ActionTypes";

function* fetchBalance() {
    try {
        const balance = yield call(balanceService.getBalance);
        yield put({type: FETCH_BALANCE_SUCCEEDED, balance});
    } catch (error) {
        console.error(error);
        yield put({type: FETCH_BALANCE_FAILED});
    }
}

function* addBalance({value}) {
    try {
        yield call(balanceService.addBalance, value);
        yield put({type: ADD_BALANCE_SUCCEEDED});
    } catch (error) {
        console.error(error);
        yield put({type: ADD_BALANCE_FAILED});
    }
}

function* watchFetchBalance() {
    yield takeLatest(FETCH_BALANCE, fetchBalance);
}

function* watchAddBalance() {
    yield takeLatest(ADD_BALANCE, addBalance);
}

export default function* balance() {
    yield all([
        fork(watchFetchBalance),
        fork(watchAddBalance)
    ]);
}
