import axios from 'axios';

export const getData = async (url) => {
    const response = await axios.get(url);

    switch (response.status) {
        case 401:
            throw new Error('401 - Unauthorised');
        case 200:
            return await response.data;
        default:
            throw new Error('No understandable response');
    }
};

export const postData = async (
    url = '',
    data = {},
    method = 'POST',
    contentType= 'application/json'
) => {
    const response = await axios({
        method,
        url,
        headers: { 'Content-Type': contentType },
        data
    });
    return await response.data;
};
