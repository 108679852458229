import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import TopNav from "./TopNav/TopNav";
import {clearWs, fetchAllMessages, hideLeftSidebar, setChatId, showLeftSidebar} from "../../../appRedux/actions";
import ConversationsList from "./ConversationsList/ConversationsList";
import MyCommunication from "./MyCommunication/MyCommunication";
import {getSelectedConversation} from "./utils";
import SidebarRight from "./SidebarRight/SidebarRight";
import NewConversationModal from "./NewConversationModal/NewConversationModal";
import CircularProgress from "../../CircularProgress";
import {NotificationContainer, NotificationManager} from "react-notifications";
import IntlMessages from "../../../util/IntlMessages";
import './ChatNew.less';
import ConversationsListArchive from "./ConversationsList/ConversationsListArchive";
import ChatLimitWarning from "./ChatLimitWarning/ChatLimitWarning";
import { isTrialExpiredSelector } from "../../../appRedux/reducers/Accounts";

class ChatNew extends Component {
  state = {
    sidebarOpen: false,
    width: 0,
    newConversationModalVisible: false
  };

  componentDidMount() {
    this.props.fetchAllMessages(this.props.isArchive);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isArchive !== prevProps.isArchive) {
      this.props.fetchAllMessages(this.props.isArchive);
    }
    this.props.clearWs();
  }

  showNewMessageNotifications(phoneNumbers) {
    phoneNumbers.map(number => this.createNotificationSuccess(number));
  }

  createNotificationSuccess = (number) => {
    NotificationManager.success(number, <IntlMessages id="notification.received"/>);
  };

  createNotificationInfo = (message) => {
    NotificationManager.info(message);
  };

  backToList = () => {
    this.props.setChatId('');
  };

  toggleSidebarOpen = () => {
    this.setState(prevState => ({
      sidebarOpen: !prevState.sidebarOpen
    }))
  };

    setSidebarClose = () => {
      this.setState({sidebarOpen: false})
    };

  toggleLeftSidebar = () => this.props.global.shouldShowRightSidebarMobile ?
    this.props.hideLeftSidebar() : this.props.showLeftSidebar();

  updateWindowDimensions = () => {
    this.setState({width: window.innerWidth});
  };

  hideNewConversationModalVisible = () => {
    this.setState({
      newConversationModalVisible: false
    });
  };

  showNewConversationModalVisible = () => {
    this.setState({
      newConversationModalVisible: true
    });
  };

  render() {
    const selectedConversation = getSelectedConversation(this.props.messages, this.props.global.chatId);
    const shouldShowSidebar = selectedConversation && (this.state.sidebarOpen || this.state.width >= 1200);

    return (
      <div className={this.props.isArchive ? 'chat-new chat-new--archive' : 'chat-new'}>
        <NotificationContainer />

        {this.state.sidebarOpen &&
          <div className='chat-new--dark-cover' onClick={this.toggleSidebarOpen}/>
        }
        {this.props.global.shouldShowLeftSidebarMobile &&
          <div className='chat-new--dark-cover' onClick={this.props.hideLeftSidebar}/>
        }


        {this.props.global.isLoading ?
          <div className="gx-loader-view">
            <CircularProgress/>
          </div> :
          <React.Fragment>
            <ChatLimitWarning
                isArchive={this.props.isArchive}
                conversationsLength={this.props.messages?.length}
            />
            <TopNav
              selectedConversation={selectedConversation}
              backToList={this.backToList}
              toggleSidebar={this.toggleSidebarOpen}
              toggleLeftSidebar={this.toggleLeftSidebar}
              showNewConversationModalVisible={this.showNewConversationModalVisible}
              isArchive={this.props.isArchive}
              isTrialExpired={this.props.isTrialExpired}
            />

            {this.props.isArchive ?
              <ConversationsListArchive
                classList={!this.props.global.chatId ? '' : 'mobile-hide tablet-hide'}
              />
              :
              <ConversationsList
                classList={!this.props.global.chatId ? '' : 'mobile-hide tablet-hide'}
              />
            }

            <MyCommunication
              classList={this.props.global.chatId ? '' : 'mobile-hide tablet-hide'}
              isArchive={this.props.isArchive}
              selectedConversation={selectedConversation}
              isTrialExpired={this.props.isTrialExpired}
            />

            {shouldShowSidebar &&
              <div className='chat-new__right-sidebar'>
                <SidebarRight
                  selectedConversation={selectedConversation}
                  users={this.props.users}
                  isArchive={this.props.isArchive}
                  createNotificationInfo={this.createNotificationInfo}
                  setSidebarClose={this.setSidebarClose}
                />
              </div>
            }

            <NewConversationModal
              visible={this.state.newConversationModalVisible}
              hideNewConversationModal={this.hideNewConversationModalVisible}
            />
          </React.Fragment>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  global: state.global,
  messages: state.messages,
  users: state.users,
  isTrialExpired: isTrialExpiredSelector(state),
});

export default connect(mapStateToProps, {
  fetchAllMessages,
  setChatId,
  showLeftSidebar,
  hideLeftSidebar,
  clearWs
})(ChatNew);
