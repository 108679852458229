import React from "react";
import { PhoneOutlined, CompassOutlined, ClockCircleOutlined, UserOutlined } from '@ant-design/icons';
import countryList from 'country-list';
import moment from 'moment-timezone';
import { formatPhoneNumberForDisplay, getCountryCode } from "../../../../util/helpers";
import CountryFlag from "../../../CountryFlag/CountryFlag";
import './GeneralInfoRectangle.less';
import IntlMessages from "../../../../util/IntlMessages";

const GeneralInfoRectangle = ({selectedConversation}) => {

  const countryCode = getCountryCode(selectedConversation.clientNumber);

  const getTime = () => {
    const timezones = countryCode ? moment.tz.zonesForCountry(countryCode) : [];
    const now = timezones?.length ? moment.utc().tz(timezones[0]) : moment();
    return now.format('HH:mm');
  };

  return (
    <div className="user-details__main general_info">
      <div className="user-details__box">
        <p className='user-details__title'><IntlMessages id="chats.generalInfo"/></p>
        <div className="user-details__bottom user-details__text">
          {selectedConversation.contactDetails && (selectedConversation.contactDetails.properties.name || selectedConversation.contactDetails.properties.surname) && (
            <p><UserOutlined />{`${selectedConversation.contactDetails.properties.name || ''} ${selectedConversation.contactDetails.properties.surname || ''}`}</p>
          )}
          <p className="phone-wrapper">
              <PhoneOutlined type="phone"/>
              {countryCode && (
                  <CountryFlag countryCode={countryCode} />
              )}
              {formatPhoneNumberForDisplay(selectedConversation.clientNumber)}
          </p>
          {countryCode && (
              <p><CompassOutlined />{countryList.getName(countryCode)}</p>
          )}
          <p><ClockCircleOutlined />{getTime()}</p>
        </div>
      </div>
    </div>
  )
};

export default GeneralInfoRectangle;
