import {CANNED_FETCH_SUCCEEDED} from "../../constants/ActionTypes";


const initialState = [];

const cannedResponses = (state = initialState, action) => {
  switch (action.type) {
    case CANNED_FETCH_SUCCEEDED:
      return [...action.canned];
    default:
      return state;
  }
};

export default cannedResponses;