import React from "react";
import { injectIntl } from 'react-intl';
import { Avatar } from "antd";
import { PaperClipOutlined, TeamOutlined } from '@ant-design/icons';
import { formatPhoneNumberForDisplay, formatTime, hourMinuteOnly, matchUser } from "../../../../util/helpers";
import IntlMessages from "../../../../util/IntlMessages";

const SentMessageCell = ({message, users, intl}) => {
    const user = matchUser(message.userId, users);

    const getFormattedStatus = () => {
        if (message.last) {
            switch (message.status) {
                case 'DELIVERED':
                case 'PENDING':
                case 'PROCESSING':
                case 'CARRIER_PENDING':
                    return <IntlMessages id={`messageStatus.${message.status}`}/>;
                case 'UNDELIVERED':
                case 'BILLING_ERROR':
                    return <>
                        <IntlMessages id={`messageStatus.${message.status}`}/>
                        {/*<strong className="retry-message">| <IntlMessages id="message.tryAgain"/></strong>*/}
                    </>;
                default:
                    return <IntlMessages id='messageStatus.SENT'/>;
            }
        }

        switch (message.status) {
            case 'UNDELIVERED':
            case 'BILLING_ERROR':
                return <>
                    <IntlMessages id={`messageStatus.${message.status}`}/>
                    <strong className="retry-message">| <IntlMessages id="message.tryAgain"/></strong>
                </>;
            default:
                return '';
        }
    };

    return (
        <div className="gx-chat-out-item-wrapper">
            <div className="gx-chat-item gx-flex-row-reverse">
                <p className='avatar-container'>
                    <Avatar className="avatar-circle circle-flex-container"
                            src={user && user.picture}
                            alt={message.from}
                    />
                </p>
                <div className="gx-bubble-block">
                        <div className="gx-bubble" title={formatTime(message.time)}>
                            <div className="gx-message">{message.body}</div>
                        </div>
                </div>
            </div>
            <span  className="bubble-from outgoing-message-info">
                {message.properties?.attachments?.length && (
                    <a href={message.properties.attachments[0].fileUrl} target="_blank" rel="noopener noreferrer" className="attachment">
                        <PaperClipOutlined title={intl.formatMessage({id: 'message.downloadAttachment'})} />
                    </a>
                )}
                <IntlMessages id="from"/>: {message.customName || formatPhoneNumberForDisplay(message.from)}<br/>
            </span>
            <span className="bubble-time outgoing-message-info">
                {hourMinuteOnly(message.time)} {message.isBulk && (<TeamOutlined title={intl.formatMessage({id: 'bulkMessage'})} />)}<br/>
            </span>
            <span className="out-message-status">
                {message.status &&
                    getFormattedStatus()
                }
            </span>
        </div>
    )
};

export default injectIntl(SentMessageCell);
