import {all, call, put, fork, takeLatest} from 'redux-saga/effects';
import {CANNED_FETCH_FAILED, CANNED_FETCH_REQUESTED, CANNED_FETCH_SUCCEEDED} from "../../constants/ActionTypes";
import { getData } from "../../util/httpHelpers";


const fetchAllCannedFromApi = async () => await getData(`${process.env.REACT_APP_API_URL}/canned-responses`);

function* fetchAllCanned() {
  try {
    const canned = yield call(fetchAllCannedFromApi);
    yield put({type: CANNED_FETCH_SUCCEEDED, canned});
  } catch (error) {
    console.error(error);
    yield put({type: CANNED_FETCH_FAILED});
  }
}

function* fetchCanned() {
  yield takeLatest(CANNED_FETCH_REQUESTED, fetchAllCanned);
}

export default function* cannedSagas() {
  yield all([
    fork(fetchCanned),
  ]);
}
