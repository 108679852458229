import {
    ARCHIVE_CONVERSATION,
    ASSIGN_TO_ME,
    CREATE_CONVERSATION,
    MESSAGES_FETCH_REQUESTED,
    SEND_MESSAGE,
    UNARCHIVE_CONVERSATION,
    PIN_UNPIN_NOTE,
    ONLY_MESSAGES_FETCH_REQUESTED,
    ADD_TO_SPAM,
    RESTORE_FROM_SPAM,
    CHANGE_CONVERSATION_SENDER,
    FETCH_CONTACT_DETAILS,
    MARK_CONVERSATION_READ
} from "../../constants/ActionTypes";

export const fetchAllMessages = (isArchive = false, skipLoading = false, forceReload = true) => ({type: MESSAGES_FETCH_REQUESTED, isArchive: isArchive, skipLoading, forceReload });
export const fetchOnlyMessages = (conversationId) => ({type: ONLY_MESSAGES_FETCH_REQUESTED, conversationId});
export const sendMessage = (message, recipientNumber, senderNumber, channel = 'sms', mmsFile) => ({type: SEND_MESSAGE, message, recipientNumber, senderNumber, channel, mmsFile});
export const assignToMe = (conversationId) => ({type: ASSIGN_TO_ME, conversationId});
export const createConversation = (recipientNumber, senderNumber) => ({type: CREATE_CONVERSATION, recipientNumber, senderNumber});
export const changeConversationSender = (senderNumber, clientNumber) => ({type: CHANGE_CONVERSATION_SENDER, clientNumber, senderNumber});
export const archiveConversation = (id) => ({type: ARCHIVE_CONVERSATION, id});
export const unarchiveConversation = (id) => ({type: UNARCHIVE_CONVERSATION, id});
export const pinUnpinNote = (conversationId, messageId, shouldPin) => ({type: PIN_UNPIN_NOTE, conversationId, messageId, shouldPin});
export const addToSpam = (conversationId) => ({type: ADD_TO_SPAM, conversationId});
export const restoreFromSpam = (conversationId) => ({type: RESTORE_FROM_SPAM, conversationId});
export const fetchContactDetails = (number) => ({type: FETCH_CONTACT_DETAILS, number});
export const markConversationRead = (conversationId) => ({type: MARK_CONVERSATION_READ, conversationId});
