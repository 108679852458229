import {all, call, put, fork, takeLatest} from 'redux-saga/effects';
import {STATS_FETCH_FAILED, STATS_FETCH_REQUESTED, STATS_FETCH_SUCCEEDED} from "../../constants/ActionTypes";
import { getData } from "../../util/httpHelpers";

const fetchAllStatsFromApi = async () => await getData(`${process.env.REACT_APP_API_URL}/stats`);

function* fetchAllStats() {
  try {
    const stats = yield call(fetchAllStatsFromApi);
    yield put({type: STATS_FETCH_SUCCEEDED, stats});
  } catch (error) {
    console.error(error);
    yield put({type: STATS_FETCH_FAILED});
  }
}

function* fetchStats() {
  yield takeLatest(STATS_FETCH_REQUESTED, fetchAllStats);
}

export default function* statsSagas() {
  yield all([
    fork(fetchStats),
  ]);
}
