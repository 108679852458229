import {all, call, put, fork, takeLatest} from 'redux-saga/effects';
import {
    ARCHIVE_CONVERSATION,
    ASSIGN_TO_ME,
    CHANGE_NEW_CONVERSATION_ID,
    CLEAR_WS,
    CREATE_CONVERSATION,
    CREATE_CONVERSATION_SUCCEEDED,
    GLOBAL_SET_CHAT_ID,
    MESSAGES_FETCH_FAILED,
    MESSAGES_FETCH_REQUESTED,
    MESSAGES_FETCH_SUCCEEDED,
    ONLY_MESSAGES_FETCH_FAILED,
    ONLY_MESSAGES_FETCH_REQUESTED,
    ONLY_MESSAGES_FETCH_SUCCEEDED,
    PIN_UNPIN_NOTE,
    PIN_UNPIN_NOTE_FAILED,
    PIN_UNPIN_NOTE_SUCCEEDED,
    SEND_MESSAGE,
    SEND_MESSAGE_SUCCEEDED,
    UNARCHIVE_CONVERSATION,
    ADD_TO_SPAM,
    RESTORE_FROM_SPAM,
    ERROR_MESSAGE,
    FETCH_CONTACT_DETAILS,
    FETCH_CONTACT_DETAILS_SUCCEEDED,
    FETCH_CONTACT_DETAILS_EMPTY,
    MARK_CONVERSATION_READ
} from "../../constants/ActionTypes";
import {
    fetchAllMessages as fetchAllMessages_action,
    fetchContactDetails as fetchContactDetails_action,
    turnLoadingOff,
    turnLoadingOn
} from "../actions";
import { getData, postData } from "../../util/httpHelpers";

const API_ENDPOINT = process.env.REACT_APP_API_URL + '/conversations';

const fetchAllMessagesFromApi = async () => {
  return await getData(API_ENDPOINT);
};

const fetchOnlyMessagesFromApi = async (conversationId) => {
    try {
        return await getData(`${API_ENDPOINT}/${conversationId}/messages`);
    } catch (error) {
        return [];
    }
};

const fetchAllArchived = async () => {
  return await getData(`${API_ENDPOINT}?isArchived=true`);
};

const fetchContactDetails = async (phoneNumber) => {
    return await getData(`${process.env.REACT_APP_API_URL}/contacts/${phoneNumber}`);
};

const postMessage = async (message, recipientNumber, senderNumber, channel = 'sms', mmsFile) => {
    const formData = new FormData();
    formData.append('file', mmsFile);
    formData.append('from', senderNumber);
    formData.append('to', recipientNumber);
    formData.append('body', message);
    formData.append('channel', channel);
  return await postData(API_ENDPOINT, formData, 'POST', 'multipart/form-data');
};

const putPinUnpinNote = async (conversationId, messageId, shouldPin) => {
  const data = {
    "isPinnedToTop": shouldPin
  };

  try {
    await postData(`${API_ENDPOINT}/${conversationId}/messages/${messageId}/properties`, data, 'PUT');
  } catch (error) {
    console.error(error);
  }
};

const postArchive = async (id) => {
  try {
    await postData(`${API_ENDPOINT}/${id}/archive`);
  } catch (error) {
    console.error(error);
  }
};

const postUnarchive = async (id) => {
  try {
    await postData(`${API_ENDPOINT}/${id}/unarchive`);
  } catch (error) {
    console.error(error);
  }
};

const postAssignToMe = async (conversationId) => {
  try {
    await postData(API_ENDPOINT + `/${conversationId}/assign` );
  } catch (error) {
    console.error(error);
  }
};

const postAddConversationToSpam = async (conversationId) => {
    try {
        await postData(`${API_ENDPOINT}/${conversationId}/spam` );
    } catch (error) {
        console.error(error);
    }
};

const postRestoreConversationFromSpam = async (conversationId) => {
    try {
        await postData(`${API_ENDPOINT}/${conversationId}/not-spam` );
    } catch (error) {
        console.error(error);
    }
};

const postMarkConversationRead = async (conversationId) => {
    try {
        await postData(`${API_ENDPOINT}/${conversationId}/read` );
    } catch (error) {
        console.error(error);
    }
};

function* fetchAllMessages({isArchive, skipLoading, forceReload}) {
  try {
    if (!skipLoading) {
      yield put(turnLoadingOn());
    }
    const messages = yield isArchive ? call(fetchAllArchived) : call(fetchAllMessagesFromApi);
    yield put({type: MESSAGES_FETCH_SUCCEEDED, messages, forceReload});
    if (!skipLoading) {
      yield put(turnLoadingOff());
    }
    yield put({type: CLEAR_WS});
  } catch (error) {
    console.error(error);
    yield put({type: MESSAGES_FETCH_FAILED});
  }
}

function* fetchOnlyMessagesForConversation({conversationId}) {
    try {
        const messages = yield call(fetchOnlyMessagesFromApi, conversationId);
        yield put({type: ONLY_MESSAGES_FETCH_SUCCEEDED, conversationId, messages});
    } catch (error) {
        console.error(error);
        yield put({type: ONLY_MESSAGES_FETCH_FAILED});
    }
}

function* sendOneMessage({message, recipientNumber, senderNumber, channel, mmsFile}) {
  try {
    const conversation = yield call(postMessage, message, recipientNumber, senderNumber, channel, mmsFile);
    yield put({type: SEND_MESSAGE_SUCCEEDED});
    yield put({type: CHANGE_NEW_CONVERSATION_ID, id: conversation._id, clientNumber: conversation.clientNumber});
    yield put({type: GLOBAL_SET_CHAT_ID, id: conversation._id});
  } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 403) {
          yield put({type: ERROR_MESSAGE, error: 'INSUFFICIENT_FUNDS'});
      } else if (error.response && error.response.status === 400 && error.response.data?.code === "PHONE_NUMBER_ON_BLOCK_LIST") {
          yield put({type: ERROR_MESSAGE, error: 'PHONE_NUMBER_ON_BLOCK_LIST'});
      } else {
          yield put({type: ERROR_MESSAGE, error: 'ERROR_MESSAGE'});
      }
  }
}

function* pinUnpinOneNote({conversationId, messageId, shouldPin}) {
  try {
    const response = yield call(putPinUnpinNote, conversationId, messageId, shouldPin);
    yield put(fetchAllMessages_action());
    yield put({type: PIN_UNPIN_NOTE_SUCCEEDED, response});
  } catch (error) {
    console.log(error);
    yield put({type: PIN_UNPIN_NOTE_FAILED});
  }
}

function* archive({id}) {
  try {
    yield call(postArchive, id);
    yield put(fetchAllMessages_action());
  } catch (error) {
    console.log(error);
  }
}

function* unarchive({id}) {
  try {
    yield call(postUnarchive, id);
    yield put(fetchAllMessages_action(true));
  } catch (error) {
    console.log(error);
  }
}

function* assignToMeConversationId({conversationId}) {
  try {
    yield call(postAssignToMe, conversationId);
    yield put(fetchAllMessages_action(false, false, true));
  } catch (error) {
    console.log(error);
  }
}

function* addConversationToSpam({conversationId}) {
    try {
        yield call(postAddConversationToSpam, conversationId);
        yield put(fetchAllMessages_action(false, false, true));
    } catch (error) {
        console.log(error);
    }
}

function* restoreConversationFromSpam({conversationId}) {
    try {
        yield call(postRestoreConversationFromSpam, conversationId);
        yield put(fetchAllMessages_action(false, false, true));
    } catch (error) {
        console.log(error);
    }
}

function* createNewConversation({recipientNumber, senderNumber}) {
    yield put({type: CREATE_CONVERSATION_SUCCEEDED, recipientNumber, senderNumber});
    yield put(fetchContactDetails_action(recipientNumber));
}

function* fetchNumberContactDetailsMethod({number}) {
    const contact = yield call(fetchContactDetails, number);
    if (contact) {
        yield put({type: FETCH_CONTACT_DETAILS_SUCCEEDED, contact});
    } else {
        yield put({type: FETCH_CONTACT_DETAILS_EMPTY});
    }
}

function* markRead({conversationId}) {
    try {
        yield call(postMarkConversationRead, conversationId);
    } catch (error) {
        console.log(error);
    }
}

function* fetchMessages() {
  yield takeLatest(MESSAGES_FETCH_REQUESTED, fetchAllMessages);
}

function* fetchOnlyMessages() {
    yield takeLatest(ONLY_MESSAGES_FETCH_REQUESTED, fetchOnlyMessagesForConversation);
}

function* sendMessage() {
  yield takeLatest(SEND_MESSAGE, sendOneMessage);
}

function* assignToMe() {
  yield takeLatest(ASSIGN_TO_ME, assignToMeConversationId);
}

function* archiveChat() {
  yield takeLatest(ARCHIVE_CONVERSATION, archive);
}

function* unarchiveChat() {
  yield takeLatest(UNARCHIVE_CONVERSATION, unarchive);
}

function* pinUnpinNote() {
  yield takeLatest(PIN_UNPIN_NOTE, pinUnpinOneNote);
}

function* addToSpam() {
    yield takeLatest(ADD_TO_SPAM, addConversationToSpam);
}

function* restoreFromSpam() {
    yield takeLatest(RESTORE_FROM_SPAM, restoreConversationFromSpam);
}

function* createConversation() {
    yield takeLatest(CREATE_CONVERSATION, createNewConversation);
}

function* fetchNumberContactDetails() {
    yield takeLatest(FETCH_CONTACT_DETAILS, fetchNumberContactDetailsMethod);
}

function* markConversationRead() {
    yield takeLatest(MARK_CONVERSATION_READ, markRead);
}

export default function* messagesSagas() {
  yield all([
    fork(fetchMessages),
    fork(fetchOnlyMessages),
    fork(sendMessage),
    fork(assignToMe),
    fork(archiveChat),
    fork(unarchiveChat),
    fork(pinUnpinNote),
    fork(addToSpam),
    fork(restoreFromSpam),
    fork(createConversation),
    fork(fetchNumberContactDetails),
    fork(markConversationRead),
  ]);
}
