import {all} from "redux-saga/effects";
import messagesSagas from "./Messages";
import wsSagas from "./Ws";
import statsSagas from "./Stats";
import usersSagas from "./Users";
import cannedResponses from "./CannedResponses";
import accounts from "./Accounts";
import plans from "./Plans";
import balance from "./Balance";

export default function* rootSaga(getState) {
  yield all([
    messagesSagas(),
    wsSagas(),
    statsSagas(),
    usersSagas(),
    cannedResponses(),
    accounts(),
    plans(),
    balance()
  ]);
}
