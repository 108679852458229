import React from "react";
import {ConnectedRouter} from "react-router-redux";
import {Provider} from "react-redux";
import {Switch} from "react-router-dom";

import "assets/vendors/style";
import "styles/wieldy.less";
import "react-intl-tel-input/dist/main.css";
import "rc-tabs/assets/index.css";
import configureStore from "./appRedux/store";
import history from "./util/history";
// import "./firebase/firebase";
import App from "./containers/App/index";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

export const store = configureStore();

const NextApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <PrivateRoute path="/" component={App} />
      </Switch>
    </ConnectedRouter>
  </Provider>;


export default NextApp;
