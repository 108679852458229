import React from "react";
import { Link } from "react-router-dom";
import IntlMessages from "../../../../util/IntlMessages";
import './ConversationsLink.less';
import { isShoperApp } from "../../../../util/appType";

const ConversationsLink = ({link, labelKey}) => {
    return isShoperApp() ?
        <div className="conversations-link">
            <Link to={link}>
                <IntlMessages id={labelKey}/>
            </Link>
        </div>
        : null;
};

export default ConversationsLink;
