import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import axios from 'axios';
import { isAuthenticationExcludedUrl } from './util/authenticationExcludeUrls';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
                                children,
                                onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
                                ...initOptions
                              }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      if(window.location.href.includes('channels/login') && window.location.search.includes("code=")) {
          return await loginByChannels();
      }

      if(window.location.href.includes('shoper/login') && window.location.search.includes("access_token=")) {
          return await loginByShoper();
      }

      if(window.location.href.includes('channels/mobile-login')) {
          return await loginByMobile('channels');
      }

      if(window.location.href.includes('shoper/mobile-login')) {
          return await loginByMobile('shoper');
      }

      if(window.location.href.includes('livechat/login')) {
          return await loginByLivechat();
      }

      if (isAuthenticationExcludedUrl()) {
          return;
      }

      if (['channels', 'shoper', 'livechat'].includes(window.sessionStorage.getItem('x-auth-provider')) && window.sessionStorage.getItem('account')?.length) {
          setIsAuthenticated(true);
          return;
      }

      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      // TODO: make LiveChat and Auth0 redirects work together in more pleasant way
      if (!(window.location.href.includes('settings/livechat') || window.location.href.includes('settings/intercom') || window.location.href.includes('settings/slack')) && window.location.search.includes("code=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
        window.sessionStorage.setItem('x-auth-provider', '');
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

    const loginByChannels = async () => {
        try {
            const params = new URLSearchParams(window.location.search);
            const code = params.get('code');

            const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/channels/auth/token/${code}`);
            if(data && data.access_token) {
                window.sessionStorage.setItem('jwt', data.access_token);
                window.sessionStorage.setItem('x-auth-provider', 'channels');
                window.sessionStorage.setItem('x-refresh-token', data.refresh_token);

                if (data.domains?.length === 1) {
                    window.sessionStorage.setItem('account', data.domains[0].domain);
                    setIsAuthenticated(true);
                    window.location.href = '/my-dashboard';
                } else {
                    window.location.href = '/channels/choose-account';
                }
            }

        } catch (error) {
            console.error(error);
        }
    };

    const loginByShoper = async () => {
        try {
            const params = new URLSearchParams(window.location.search);
            const accessToken = params.get('access_token');
            const account = params.get('account');
            const redirectUrl = params.get('redirectUrl');

            if(accessToken) {
                window.sessionStorage.setItem('jwt', accessToken);
                window.sessionStorage.setItem('x-auth-provider', 'shoper');
                window.sessionStorage.setItem('account', account);
                setIsAuthenticated(true);
                window.location.href = redirectUrl || '/chat?id=&tab=2';
            }
        } catch (error) {
            console.error(error);
        }
    };

    const loginByMobile = async (mobileAppType) => {
        console.log("login via mobile");

        try {
            const params = new URLSearchParams(window.location.search);
            const accessToken = params.get('accessToken');
            const refreshToken = params.get('refreshToken');
            const account = params.get('account');
            const type = params.get('type');
            const redirectUrl = params.get('redirectUrl');

            console.log("redirectUrl = ", redirectUrl);

            if(accessToken && refreshToken && account && ['channels', 'shoper'].includes(type)) {
                const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/${mobileAppType}/auth/domains`, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'x-auth-provider': type,
                            'x-refresh-token': refreshToken
                        }
                });

                if (data.some(currentAccount => currentAccount.domain === account)) {
                    window.sessionStorage.setItem('jwt', accessToken);
                    window.sessionStorage.setItem('x-auth-provider', type);
                    window.sessionStorage.setItem('x-refresh-token', refreshToken);
                    window.sessionStorage.setItem('account', account);
                    setIsAuthenticated(true);
                    window.location.href = redirectUrl || '/chat?id=&tab=2';
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const loginByLivechat = async () => {
        try {
            const params = new URLSearchParams(window.location.search);
            const accessToken = params.get('accessToken');
            const account = params.get('account');
            const xAuthProvider = 'livechat';

            if(accessToken) {
                clearAuthSessionStorage();

                const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/accounts/current`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'x-auth-provider': xAuthProvider,
                    }
                });

                if (data?.name === account) {
                    window.sessionStorage.setItem('jwt', accessToken);
                    window.sessionStorage.setItem('x-auth-provider', xAuthProvider);
                    window.sessionStorage.setItem('account', account);
                    setIsAuthenticated(true);
                    window.location.href = '/bulk/sms';
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const logoutChannelsAccount = () => {
        clearAuthSessionStorage();
        setIsAuthenticated(false);
        window.location.href = '/'
    };

    const clearAuthSessionStorage = () => {
        window.sessionStorage.removeItem('jwt');
        window.sessionStorage.removeItem('x-refresh-token');
        window.sessionStorage.removeItem('x-auth-provider');
        window.sessionStorage.removeItem('account');
    }

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => window.sessionStorage.getItem('x-auth-provider') === 'channels'
            ? logoutChannelsAccount()
            : auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
