import React from "react";
import { Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {formatPhoneNumberForDisplay} from "../../../../util/helpers";
import IntlMessages from "../../../../util/IntlMessages";
import './TopNav.less';

const TopNav = ({selectedConversation, backToList, toggleSidebar, toggleLeftSidebar, showNewConversationModalVisible, isArchive, isTrialExpired}) => {

  return (
    <div className="top-nav has-border-bottom">
      <div className="top-nav__menu-container has-height-full">
        <i className="gx-icon-btn icon icon-menu"
           onClick={toggleLeftSidebar}
        />
      </div>

      <div className={'has-height-full top-nav__main top-nav__chats chat-left-part ' + (selectedConversation ? 'mobile-hide tablet-hide' : '')}>
        <span className="top-nav__element">
          {isArchive ? <IntlMessages id="chats.archive"/> : <IntlMessages id="chats"/>}
        </span>
        {!isArchive && !isTrialExpired &&
            <Button type="primary"
                    size="small"
                    className="tabs__btn top-nav__element"
                    onClick={showNewConversationModalVisible}
            >
                <span><IntlMessages id="chats.new"/></span>
            </Button>
        }
      </div>

      {selectedConversation &&
        <div className="has-height-full top-nav__main top-nav__main-right">
          <i className="gx-icon-btn icon icon-arrow-left desktop-visibility-none" onClick={backToList}/>
            {selectedConversation && selectedConversation.contactDetails ? (
                <span className="top-nav__element">
                    <div>{selectedConversation.contactDetails.properties.name} {selectedConversation.contactDetails.properties.surname}</div>
                    <div className="small">{formatPhoneNumberForDisplay(selectedConversation.clientNumber)}</div>
                </span>
            ) : (
                <span className="top-nav__element">{formatPhoneNumberForDisplay(selectedConversation.clientNumber)}</span>
            )}
          <ExclamationCircleOutlined className="info-icon" onClick={toggleSidebar} />
        </div>
      }

    </div>
  );
};

export default TopNav;
