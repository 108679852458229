import React from "react";
import {Dropdown, Menu} from "antd";


const SelectCanned = ({handleAutoComplete, cannedResponses}) => {

  const menus = () =>  (
    <Menu onClick={e => handleAutoComplete(e.item.props['data-text'])}>
      {cannedResponses && cannedResponses.map(option =>
        <Menu.Item key={option._id} data-text={option.text}>
          {option.name}
        </Menu.Item>,
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menus()} placement="topCenter" trigger={['click']}>
      <i className="gx-icon-btn icon icon-affix z-index-2"/>
    </Dropdown>
  )
};

export default SelectCanned;
