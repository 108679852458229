/* eslint-disable no-unused-vars */
import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {Avatar, Tooltip, Dropdown, Menu} from "antd";
import { UserOutlined } from '@ant-design/icons';
import {formatTime, matchUser} from "../../../../util/helpers";
import * as PropTypes from "prop-types";
import {pinUnpinNote} from "../../../../appRedux/actions";
import './NoteMessage.less';
import IntlMessages from "../../../../util/IntlMessages";

class NoteMessageCell extends Component {
  render() {
    const {message, users, pinUnpinNote, conversationId, showControls, onEdit, onDelete} = this.props;
    const user = matchUser(message.userId, users);
    const isPinned = message.properties && message.properties.find(prop => prop.isPinnedToTop === true);

    const onUnpin = () => {
      pinUnpinNote(conversationId, message._id, false);
    };

    const onPin = () => {
      pinUnpinNote(conversationId, message._id, true);
    };

    const menu = (
      <Menu>
{/*        <Menu.Item key="1" onClick={() => onEdit(message.body)}><IntlMessages id="notes.edit"/></Menu.Item>
        <Menu.Item key="2" onClick={onDelete}><IntlMessages id="notes.delete"/></Menu.Item>*/}
        {!isPinned ? <Menu.Item key="3" onClick={onPin}><IntlMessages id="notes.pin"/></Menu.Item>
          : <Menu.Item key="4" onClick={onUnpin}><IntlMessages id="notes.unpin"/></Menu.Item>
        }
      </Menu>
    );

    const menuAndPin = () => {
      if (!showControls) {
        return <div className="sign-x" onClick={onUnpin}>x</div>
      }

      return (
        <React.Fragment>
          { isPinned ? <i className="gx-icon-btn icon icon-affix"/> : ''}

          <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
            <i className="gx-icon-btn icon icon-ellipse-v"/>
          </Dropdown>
        </React.Fragment>
      );
    };

    return (
      <div className="gx-chat-item gx-flex-row-round">
        <Tooltip placement="topRight" title={formatTime(message.time)}>
          <div className="gx-bubble note-message">
            <div className="note-message__text">
              <Avatar className="gx-size-40 gx-align-self-end"
                      src={user && user.picture}
                      alt={message.from}
                      icon={<UserOutlined/>}
              />
              <div className="gx-message">{message.body}</div>
            </div>
            <div className="note-message__controls">
              {menuAndPin()}
            </div>
          </div>
        </Tooltip>
      </div>
    )
  }
}

NoteMessageCell.propTypes = {
  message: PropTypes.any,
  users: PropTypes.any,
  conversationId: PropTypes.any,
  showControls: PropTypes.any
};

NoteMessageCell.defaultProps = {
  conversationId: null,
  showControls: null,
  onEdit: null,
  onDelete: null
};

export default connect(null, {
  pinUnpinNote
})(NoteMessageCell);
