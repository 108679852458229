import React from "react";
import './DateCell.less';

const DateCell = ({dateString}) => (
    <div className="gx-chat-item gx-flex-row-round system-message date-cell">
        <hr/>
        <div className="gx-bubble">
            <div className="gx-message">{dateString}</div>
        </div>
        <hr/>
    </div>
);

export default DateCell;
