/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import { injectIntl } from 'react-intl';
import { notification, Upload } from 'antd';
import { DeleteFilled, PaperClipOutlined } from '@ant-design/icons';
import axios from "axios";
import * as smsCounter from "@marcinkowalczyk/due-sms-counter";
import * as smartEncode from "@marcinkowalczyk/smartencoding";
import SelectSender from "../../../chat/SelectSender/SelectSender";
import SelectCanned from "../../../chat/SelectCanned";
import IntlMessages from "../../../../util/IntlMessages";
import { getCountryCode } from "../../../../util/helpers";
import './TypingArea.less';

const TypingArea = ({
                        message,
                        updateTypingAreaValue,
                        updateMmsFile,
                        submitComment,
                        isArchive,
                        handleAutoComplete,
                        cannedResponses,
                        senderNumber,
                        clientNumber,
                        phoneNumbersAll,
                        updateSenderNumber,
                        focusTypingArea,
                        intl
}) => {

  const unicodeMaxLength= 396;
  const nonUnicodeMaxLength = 912;
  const [smsCount, setSmsCount] = useState(0);
  const [isUnicode, setIsUnicode] = useState(false);
  const [mmsFile, setMmsFile] = useState();
  const [mmsLimitations, setMmsLimitations] = useState();
  const isMmsAvailable = ['US', 'CA'].includes(getCountryCode(clientNumber));

  useEffect(async () => {
      if (isMmsAvailable) {
          const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/conversations/mms-limitations`);
          setMmsLimitations({
              ...data,
              mmsAvailableContentTypes: data.mmsAvailableContentTypes?.filter(item => item !== 'text/plain')
          });
      }

      const smsStatus = smsCounter.calcCharAndSmsCount(smartEncode.remove(message));
      setSmsCount(message.length ? smsStatus.sms_count : 0);
      setIsUnicode(smsStatus.is_unicode);

      if(smsStatus.is_unicode) {
          updateTypingAreaValue(message.substring(0, unicodeMaxLength));
      }
  }, [message]);

    const uploadProps = {
        beforeUpload: file => {
            if (!mmsLimitations.mmsAvailableContentTypes.includes(file.type)) {
                notification.error({message: intl.formatMessage({id: 'chats.mmsInvalidContentType'}, {value: mmsLimitations.mmsAvailableContentTypes?.join(", ")})});
                return false;
            }

            if (mmsLimitations.mmsMaxFileSize < file.size) {
                notification.error({message: intl.formatMessage({id: 'chats.mmsInvalidFileSize'}, {value: mmsLimitations.mmsMaxFileSize / 1024})});
                return false;
            }

            setMmsFile(file);
            updateMmsFile(file);
            return false;
        },
        fileList: []
    };

    const removeMmsFile = () => {
        setMmsFile(undefined);
        updateMmsFile(undefined);
    };

  return isArchive ? (
    <div className="gx-chat-main-footer is-archive"/>
  ) : (
    <div className="gx-chat-main-footer typing-area">
      <div className="gx-flex-row gx-align-items-center text-area-wrapper">
        <div className="gx-col col-text-area">
          <div className="gx-form-group">
            <textarea
              id="required" className="gx-border-0 ant-input gx-chat-textarea"
              onChange={(event) => updateTypingAreaValue(event.target.value)}
              onSelect={() => focusTypingArea()}
              value={message}
              placeholder={intl.formatMessage({id: 'chats.textMessage'})}
              maxLength={isUnicode ? unicodeMaxLength : nonUnicodeMaxLength}
            />
          </div>
        </div>
      </div>

      <div className="gx-flex-row gx-align-items-center short-list" style={{maxHeight: 51}}>
        <div className='typing-area--justify-flex-left'>
            {isMmsAvailable && mmsLimitations ? (
              <Upload
                  {...uploadProps}
                  maxCount={1}
                  accept={mmsLimitations.mmsAvailableContentTypes?.join(", ")}
              >
                <i className='icon icon-attachment gx-icon-btn z-index-2 ant-dropdown-trigger' />
              </Upload>
            ) : null}

          <SelectCanned
            cannedResponses={cannedResponses}
            handleAutoComplete={handleAutoComplete}
          />
          <SelectSender
            selectedValue={senderNumber}
            senderList={phoneNumbersAll}
            updateSenderNumber={updateSenderNumber}
          />
        </div>
        <div className='typing-area__send' onClick={() => {
            submitComment();
            removeMmsFile();
        }}>
            <IntlMessages id={mmsFile ? "chats.sendMms" : "chats.send"}/>
        </div>
      </div>
      <div className="sms-count">
          {mmsFile ? (
              <>
                  <span><PaperClipOutlined/>{mmsFile.name}</span>
                  <DeleteFilled theme="filled" className="delete-icon" onClick={() => removeMmsFile()}/>
              </>
          ) : (
            <>
                <IntlMessages id="conversation.smsCount"/>:
                <span className="value">{smsCount}</span>
            </>
          )}
      </div>
    </div>
  );
};

export default injectIntl(TypingArea);
