import {
    FETCH_BALANCE_SUCCEEDED,
} from "../../constants/ActionTypes";


const initialState = {
    monetary: null,
    lead: null,
    sms: [],
};

const balance = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BALANCE_SUCCEEDED:
            return {
                ...state,
                sms: action.balance.sms,
                lead: action.balance.lead,
                monetary: action.balance.monetary,
            };
        default:
            return state;
    }
};

export default balance;
