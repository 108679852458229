import {
  GLOBAL_SET_TAB_ID,
  GLOBAL_SET_CHAT_ID,
  GLOBAL_SHOW_LEFT_SIDEBAR,
  GLOBAL_HIDE_LEFT_SIDEBAR,
  GLOBAL_TURN_LOADING_ON,
  GLOBAL_TURN_LOADING_OFF,
  GLOBAL_SCROLL_ON,
  GLOBAL_SCROLL_OFF,
  ADD_MESSAGE_FOR_NUMBER,
  CHANGE_MESSAGE_FOR_NUMBER,
  ERROR_MESSAGE,
  REMOVE_ERROR_MESSAGE,
  GLOBAL_SET_PERMISSIONS
} from "../../constants/ActionTypes";
import { LOCATION_CHANGE } from 'react-router-redux';


const initialSettings = {
  tabId: "2",
  chatId: "",
  shouldShowLeftSidebarMobile: false,
  isLoading: false,
  scrollToBottom: false,
  errorMessage: '',
  permissions: null,
  phoneNumber: null
};

const global = (state = initialSettings, action) => {
  switch (action.type) {
    case GLOBAL_SET_TAB_ID:
      return {
        ...state,
        tabId: action.id
      };
    case GLOBAL_SET_CHAT_ID:
      return {
        ...state,
        chatId: action.id
      };
    case GLOBAL_SHOW_LEFT_SIDEBAR:
      return {
        ...state,
        shouldShowLeftSidebarMobile: true
      };
    case GLOBAL_HIDE_LEFT_SIDEBAR:
      return {
        ...state,
        shouldShowLeftSidebarMobile: false
      };
    case GLOBAL_TURN_LOADING_ON:
      return {
        ...state,
        isLoading: true
      };
    case GLOBAL_TURN_LOADING_OFF:
      return {
        ...state,
        isLoading: false
      };
    case GLOBAL_SCROLL_ON:
      return {
        ...state,
        scrollToBottom: action.clientNumber
      };
    case GLOBAL_SCROLL_OFF:
      return {
        ...state,
        scrollToBottom: false
      };
    case ADD_MESSAGE_FOR_NUMBER:
    case CHANGE_MESSAGE_FOR_NUMBER :
      return {
        ...state
      };
    case LOCATION_CHANGE:
      const urlParams = new URLSearchParams(window.location.search);
      return {
        ...state,
        tabId: urlParams.get('tab') || initialSettings.tabId,
        chatId: urlParams.get('id') || initialSettings.chatId,
        phoneNumber: urlParams.get('phoneNumber') || null,
      };
    case ERROR_MESSAGE:
      return {
        ...state,
        error: action.error
      };
    case REMOVE_ERROR_MESSAGE:
      return {
        ...state,
        error: ''
      };
    case GLOBAL_SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions
      };
    default:
      return state;
  }
};

export default global;
