import {replace} from 'react-router-redux';
import {GLOBAL_SET_CHAT_ID, GLOBAL_SET_TAB_ID} from "../../constants/ActionTypes";

export default store => next => action => {
  const state = store.getState();
  if (action.type === GLOBAL_SET_TAB_ID) {
    store.dispatch(replace(`chat?id=${state.global.chatId}&tab=${action.id}`));
  } else if (action.type === GLOBAL_SET_CHAT_ID) {
    store.dispatch(replace(`chat?id=${action.id}&tab=${state.global.tabId}`));
  }

  return next(action);
}