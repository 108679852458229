import React, {Component} from "react";
import ChatUserList from "./../ChatUserList/ChatUserList";
import CustomScrollbars from "util/CustomScrollbars";
import connect from "react-redux/es/connect/connect";
import {setChatId, setTabId} from "../../../../appRedux/actions";
import SearchBox from "../../../SearchBox";
import './ConversationsListArchive.less';
import IntlMessages from "../../../../util/IntlMessages";
import ConversationsLink from "../ConversationsLink/ConversationsLink";


class ConversationsListArchive extends Component {
  state = {
    searchPhrase: '',
    filteredMessages: [],
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.messages !== this.props.messages) {
      this.setState({
        filteredMessages: this.props.messages,
      })
    }
  }

  componentDidMount(props) {
    this.setState({
      filteredMessages: this.props.messages,
    })
  }

  updateSearchChatUser = (evt) => {
    this.setState({
      searchPhrase: evt.target.value,
      filteredMessages: this.filterMessages(evt.target.value),
    });
  };

  filterMessages = (phrase) => {
    return this.props.messages.filter(conversation => conversation.clientNumber.includes(phrase))
  };

  onSelectConversation = (conversationElement) => {
    this.props.setChatId(conversationElement._id);
  };

  render () {
    return (
      <div className={"gx-chat-sidenav-main gx-chat-sidenav-main--archive " + this.props.classList}>

        <div className="gx-chat-sidenav-header">
          <div className="gx-chat-search-wrapper">
            <SearchBox styleName="gx-chat-search-bar gx-lt-icon-search-bar-lg"
                       placeholder="Search user by number"
                       onChange={this.updateSearchChatUser}
                       value={this.state.searchPhrase}
            />
          </div>
        </div>

        <div className="gx-chat-sidenav-content chat-left-part">
          <CustomScrollbars className="gx-chat-sidenav-scroll-tab-1">
            {this.state.filteredMessages.length === 0 ?
              <div className="gx-p-5"><IntlMessages id="chats.noConversationsFound"/></div>
              :
              <ChatUserList chatUsers={this.state.filteredMessages}
                            selectedChatId={this.props.global.chatId}
                            onSelectUser={this.onSelectConversation}
              />
            }
          </CustomScrollbars>
          <ConversationsLink link="/chat?id=&tab=2" labelKey="conversationsList"/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({global, messages}) => {
  return {
    global,
    messages
  }
};

export default connect(mapStateToProps, {
  setTabId,
  setChatId
})(ConversationsListArchive);
