import {
  WEBSOCKET_MESSAGE_RECEIVED,
  CLEAR_WS
} from "../../constants/ActionTypes";

const initialState = {
  messages: []
};
const ws = (state = initialState, action) => {
  switch (action.type) {
    case WEBSOCKET_MESSAGE_RECEIVED:
      return {
        ...state,
        messages: [...state.messages, action.text]
      };
    case CLEAR_WS:
      return initialState;
    default:
      return state;
  }
};

export default ws;
