import React from "react";
import {Avatar} from "antd";
import {matchUser} from "../../../../util/helpers";
import IntlMessages from "../../../../util/IntlMessages";

const AssignedRectangle = ({selectedConversation, users}) => {

  const assignedRectangle = () => {
    const user = matchUser(selectedConversation.assignedTo, users);

    if (!user) {
      return unassignedRectangle();
    }

    return (
      <div className="user-details__box user-details__separator">
        <p className='user-details__title'><IntlMessages id="chats.assignedTo"/></p>
        <div className="user-details__data">
          <Avatar className="gx-size-40 gx-align-self-end"
                  src={user && user.picture}
                  alt={selectedConversation.from}
          />
          <div className="user-details__text-assigned">
            <p title={user.name}>{user.name}</p>
            <small title={user.email}>{user.email}</small>
          </div>
        </div>
      </div>
    )
  };

  const unassignedRectangle = () => (
    <div className="user-details__box">
      <p><IntlMessages id="chats.unassigned"/></p>
    </div>
  );

  return (
    <div className="user-details__main assigned-to">
      {selectedConversation.assignedTo ? assignedRectangle() : unassignedRectangle()}
    </div>
  )
};

export default AssignedRectangle;
