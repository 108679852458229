import React from "react";
import {formatTime} from "../../../../util/helpers";

const WhisperMessageCell = ({message}) => {
  return (
    <div className="gx-chat-item gx-flex-row-round">

      <div className="gx-bubble private-message">
        <div className="gx-message">{message.body}</div>
        <div className="gx-time gx-text-muted gx-text-right gx-mt-2">{formatTime(message.time)}</div>
      </div>

    </div>
  )
};

export default WhisperMessageCell;
