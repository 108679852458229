import React from "react";
import UserCell from "./UserCell/UserCell";
import {clone} from "../../../../util/helpers";

const sortConversationsStartWithNew = (conversations) => {
  if (!conversations) {
    return;
  }

  let conversationSorted = clone(conversations);
  conversationSorted.sort(function (a, b) {
    const lastMessageA = a.lastMessage;
    const lastMessageB = b.lastMessage;

    if (!lastMessageA) {
      return -1;
    } else if (!lastMessageB) {
      return 1
    }

    return (lastMessageA.time > lastMessageB.time) ? -1 : 1;
  });

  return conversationSorted;
};

const ChatUserList = ({chatUsers, selectedChatId, onSelectUser}) => {

  const sortedChatUsers = sortConversationsStartWithNew(chatUsers);

  return (
    <div className="gx-chat-user">
      {sortedChatUsers && sortedChatUsers.map((chat, index) => {
        return <UserCell key={index} chat={chat} selectedChatId={selectedChatId} onSelectUser={onSelectUser} isUnread={!chat.isRead}/>
      }
      )}
    </div>
  )
};

export default ChatUserList;
