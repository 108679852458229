import * as React from "react";
import {Avatar, Popover} from "antd";
import jwt_decode from "jwt-decode";
import { connect } from "react-redux";
import {useAuth0} from "../../react-auth0-spa";
import IntlMessages from "../../util/IntlMessages";
import { setPermissions } from "../../appRedux/actions";
import { BULK_MESSAGING } from "../../util/permissions";

const UserProfile = ({initWs, hiddenHtml = false, accountName, setPermissions, permissions}) => {
  const { isAuthenticated, loginWithRedirect, logout, loading, user, getTokenSilently } = useAuth0();

  if (!isAuthenticated) {
    return <button onClick={() => loginWithRedirect({})}>Log in</button>
  }

  const provider = window.sessionStorage.getItem('x-auth-provider');

    const setGlobalPermissions = permissionsParam => {
        if (!permissions) setPermissions(permissionsParam);
    };

  // TODO: this logic needs to be placed somewhere else as the code here is invoked way too many times
  if (user) {
    window.sessionStorage.setItem('sub', user.sub);
    getTokenSilently().then(jwt => {
      window.sessionStorage.setItem('jwt', jwt);
      initWs(jwt);
      const decodedToken = jwt_decode(jwt);
      const appMetadata = decodedToken['https://2waymessage.com/app_metadata'];
      setGlobalPermissions( appMetadata.permissions || []);
    });
  }
  else if (['channels', 'shoper'].includes(provider)) {
      const jwt = window.sessionStorage.getItem('jwt');
      const decodedToken = jwt_decode(jwt);
      window.sessionStorage.setItem('sub', decodedToken.user_name);
      initWs(jwt);
      setGlobalPermissions([BULK_MESSAGING]);
  } else if (provider === 'livechat') {
      setGlobalPermissions([BULK_MESSAGING]);
  }

  const logOut = () => {
    // Communicate with mobile app
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        type: 'loggedOut'
      }));

      setTimeout(() => {
        logout({returnTo: window.origin});
      }, 3000);
    } else {
      logout({returnTo: window.origin});
    }
  };

  const userMenuOptions = (
      <>
        {!['channels', 'shoper'].includes(provider)
            ? <div><IntlMessages id="account.name"/>: {accountName}<br/><br/></div>
            : null
        }
        <ul className="gx-user-popover">
          {!isAuthenticated && <li onClick={() => loginWithRedirect({})}>Log in</li>}
          {isAuthenticated && <li onClick={() => logOut()}>Log out</li>}
        </ul>
      </>
  );

  if (hiddenHtml) {
      return "";
  }

  return (
    <div className="gx-flex-row gx-align-items-center gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src={loading || !user ? 'Loading...' : user.picture}
                className="gx-size-40 gx-pointer gx-m-3" alt=""/>
      </Popover>
    </div>
  );
};

const mapStateToProps = ({accounts, global}) => {
    return ({
        accountName: accounts?.accountName,
        permissions: global?.permissions
    });
};

export default connect(mapStateToProps, {setPermissions})(UserProfile);
